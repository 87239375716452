import { BaseService } from './_BaseService';

export class SalesPipelineService extends BaseService {
    loadInitialData = async () => {
        return await this.get(`sales-pipeline`);
    }
    loadSectionData = async (section, status, page, dangerThreshold, warningThreshold, market, team, consultant, maxAge, ageField) => {
        return await this.post(`sales-pipeline/section`, {section, status, page, dangerThreshold, warningThreshold, market, team, consultant, maxAge, ageField});
    }
    loadDialogData = async (id) => {
        return await this.get(`sales-pipeline/opportunities/${id}`);
    }
    saveDialogData = async (id, data) => {
        return await this.put(`sales-pipeline/opportunities/${id}`, data);
    }
    saveMessagesData = async (id, data) => {
        return await this.post(`sales-pipeline/opportunities/${id}/messages`, data);
    }
    updateConsultationStatusAndDisposition = async (id, status, disposition) => {
        return await this.post(`sales-pipeline/opportunities/${id}/disp`, { status, disposition });
    }
    escalate = async (id, message) => {
        return await this.post(`sales-pipeline/opportunities/${id}/escalate`, { message });
    }
}