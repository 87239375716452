import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState, useRef } from "react";
import { SalesPipelineService } from "../../services/SalesPipelineService";

//Components
import {CardItem} from "../CardItem/CardItem.component";

// Style
import "./Card.style.css";

export const Card = ({ section, config, hiddenItemsList, first, last, navigate, active, handleHideColumns, filters, loadingDashboard, loadingDashboardStop, refreshPipeline, consultants }) => {

	const listInnerRef = useRef();
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	useEffect(() => {        
		setLoading(true);
        loadInitialData();
    }, [section]);

	const loadInitialData = async () => {

		const service = new SalesPipelineService();
        const _items = await service.loadSectionData(section, config.id, 0, config.dangerThreshold, config.warningThreshold, filters?.market, filters?.team, filters?.consultant, config.maxAge, config.ageField);
		setCurrentPage(0);
        setItems(_items);
        setLoading(false);
    }

	const updateMethods = async () => {
		await loadingDashboardStop();
		await setLoading(true);
        await loadInitialData();
	}

	if(loadingDashboard){
		updateMethods();
	}

	const onScroll = async () => {
		if (listInnerRef.current) {
		  const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
		  if (scrollTop + clientHeight + 200 >= scrollHeight && !loadingMore && items.cardList.length < items.total) {
			setLoadingMore(true);
			const service = new SalesPipelineService();
			const _items = await service.loadSectionData(section, config.id, currentPage + 1, config.dangerThreshold, config.warningThreshold, filters?.market, filters?.team, filters?.consultant, config.maxAge);
			let final_items = {
				total: items.total,
				cardList: [...items.cardList, ..._items.cardList]
			}
			setItems(final_items);
			setCurrentPage(currentPage + 1);
			setLoadingMore(false);
		  }
		}
	  };

	return (
		(hiddenItemsList && hiddenItemsList.indexOf(config.id) === -1) ?
		<div className={`sp_card-wrapper ${active ? 'enabled' : 'disabled'}`}>
			{ true && <>
			<div className="sp_card-header">
				<div className="sp_card-title-wrapper">
					<i onClick={() => navigate(-1)} className={`pi pi-arrow-left ${ first ? 'disable' : ''}`}></i>
					<h2 className="sp_card-title">{config.id}</h2> <i onClick={() => handleHideColumns(config.id)} className={`pi pi-eye-slash float-right`}></i>
					<i onClick={() => navigate(1)} className={`pi pi-arrow-right ${ last ? 'disable' : ''}`}></i>
				</div>
				
				<div className="sp_card-money-summary">
					<div className={config.showValue === true ? "sp_card-total" : "sp_card-total_no_content"}>
						<span>Total Jobs: </span>
						{ loading && <span>...</span>}
						{!loading && <span>{items?.total}</span>}
					</div>
					<div className="sp_card-estimated">
						{config.showValue === true ? (
						<><span>Estimated: </span><>
									{loading && <span>...</span>}
									{!loading && <span>{items?.totalSize} Kwz</span>}
								</></>
						) : <div style={{ height: '15px'}}></div> }
					</div>
				</div>
				
				{/* <div className="sp_progress_bar">
					<div className="sp_progress-wrapper">
						<div
							className="sp_progress__bar"
							style={{width: `${loading ? 0 : percentage}%`}}
						></div>
					</div>
					<div className="sp_progress-percentage">
						{ loading && <span>...</span>}
						{!loading && <span>{percentage}%</span>}
					</div>
				</div> */}
			</div>
			
			{/*	Body */}
			<div className="sp_card-body--wrapper">
				<div className="sp_card-body--items-wrapper"
					onScroll={onScroll}
					ref={listInnerRef}
					>
					{ loading && <ProgressSpinner/>}
					{!loading && <>
						{ items && 
						items?.cardList?.map((i,ind) => 
						<CardItem 
							key={`${i.id}_${ind}`}
							id={i.id}
							name={i.name}
							trackCode={i.biz_id}
							date={i.date}
							value={i.value}
							status={i.critical_level}
							stage={i.status}
							showValue={config.showValue}
							disposition={i.demo_disposition}
							showDisposition={config.showDisposition}
							refreshPipeline={refreshPipeline}
							config={config}
							job={i}
							consultants={consultants}
						/>
						)}
					</> }
					{ loadingMore && <span style={{color: '#ccc', fontStyle: 'italic', width: '100%', textAlign: 'center'}}>loading more items...</span>}
				</div>
			</div>
			</>}
		</div>
		: null
	);
};
