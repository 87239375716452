import { BaseService } from './_BaseService';

export class ReferrerService extends BaseService {

    sendLink = async (referrer, fromSales) => {
        return await this.post(`sales/send-link`, { referrer, fromSales });
    }

    list = async () => {
        return await this.get(`sales/referrers`);
    }

    resendInvite = async (referrer) => {
        return await this.post(`sales/resend-sales-invite`, { referrer });
    }
}