import React, { useRef, useEffect } from "react";
import { Button } from "primereact/button";

import "../assets/css/home/desktop-large.css";
import "../assets/css/home/mobile.css";
import { AuthorizationService } from "../services/AuthorizationService";
import HomeCard from "../components/HomeCard/HomeCard";

export function Home() {
  const authorizationService = new AuthorizationService();

  return (
    <>
      <div className="screen home">
        <div id="left-panel">
          {authorizationService.can(
            AuthorizationService.Permissions.ACCESS_REFERRAL_PROGRAM
          ) && (
            <>
              <HomeCard
                enabled={true}
                title={"Referral Program"}
                icon={"/assets/layout/images/ico-home-referrals.png"}
                subtitle={
                  "Refer your friends, neighbors and family and earn cash."
                }
                targetPath="/referral-list"
              />
            </>
          )}
          
          {authorizationService.can(
            AuthorizationService.Permissions.ACCESS_SALES_PIPELINE
          ) && (
            <>
              <HomeCard
                enabled={true}
                title={"Sales Pipeline"}
                icon={"/assets/layout/images/ico-home-sales-pipeline.png"}
                subtitle={
                  "View the sales pipeline, track every step of the sales process in detail."
                }
                targetPath="/sales-pipeline"
              />
            </>
          )}
          
          <HomeCard
            enabled={false}
            title={"Sales Dashboard"}
            icon={"/assets/layout/images/ico-home-sales-dashboard.png"}
            subtitle={
              "Find the details of all sales and get statistics on sales performance."
            }
          />
          <HomeCard
            enabled={false}
            title={"Aurora Proposal"}
            icon={"/assets/layout/images/ico-home-aurora.png"}
            subtitle={
              "Create or edit proposals on the state-of-the-art Aurora proposal tool."
            }
          />
          <HomeCard
            enabled={true}
            title={"Sales Hub"}
            icon={"/assets/layout/images/ico-home-commissions.png"}
            subtitle={
              "Track the amount of commissions earned out of completed sales."
            }
            targetPath="https://poweredbyelevation.sharepoint.com/sites/ElevatedSalesHub"
            openInNewWindow={true}
          />
          <HomeCard
            enabled={true}
            title={"Elevate U"}
            icon={"/assets/layout/images/ico-home-onboarding.png"}
            subtitle={
              "Access the content for new hires’ onboarding training process."
            }
            targetPath='https://www.schoox.com/academies/home.php'
            openInNewWindow={true}
          />
          
        </div>
        {false && (
          <div id="right-panel">
            <div className="messages-panel">
              <b>Message Center</b>
              <br />
              (Coming soon)
            </div>
          </div>
        )}
      </div>
    </>
  );
}
