import { BaseService } from './_BaseService';

export class SignUpService extends BaseService {

    checkEmail = async (email) => {
        return await this.post(`sales/check-email`, { email });
    }

    sendToken = async (name, email) => {
        return await this.post(`sales/send-token`, { name, email });
    }

    checkToken = async (token) => {
        return await this.post(`sales/check-token`, { token });
    }

    savePassword = async (password) => {
        return await this.post(`sales/create-password`, { password });
    }
}