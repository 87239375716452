import { BaseService } from './_BaseService';

export class SalesDashboardService extends BaseService {
    loadInitialData = async () => {
        return await this.get(`sales-dashboard`);
    }

    filterDashboard = async (filter) => {
        return await this.post(`sales-dashboard/dashboard`, filter);
    }

    filterRanking = async (filter) => {
        return await this.post(`sales-dashboard/ranking`, filter);
    }
}