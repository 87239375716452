export default function RightPanel(props){


    const referrals = props.referrals || [];
    const counters = {
        referrals: referrals.length,
        meetings: referrals.filter(r => r.status === "MEETING_DONE" || r.status === "ACTIVATED").length,
        sales: referrals.filter(r => r.status === "ACTIVATED").length
    }

    return (<div className='right-panel'>
    <div className='tabs'>
        <div className='tab active'>YOUR LEADS,<br/>REFERRALS & SALES</div>
        <div className='tab'>PROFILE<br/>INFORMATION</div>
    </div>
    <div className='content'>
        <div className='card referrals'>
            <div className='title'>REFERRALS</div>
            <div className='desc'> Number of potential clients you referred to Elevation</div>
            <div className='bottom'>
                <div>{ counters.referrals || 0 }</div>
                <img alt="Referrals" src="assets/layout/images/ico-referrals.png"/>
            </div>
        </div>
        <div className='card meetings'>
            <div className='title'>MEETINGS</div>
            <div className='desc'>Number of meetings we have set up with your referrals</div>
            <div className='bottom'>
            <div>{ counters.meetings || 0 }</div>
                <img alt="Meetings" src="assets/layout/images/ico-meetings.png"/>
            </div>
        </div>
        <div className='card sales'>
            <div className='title'>SALES</div>
            <div className='desc'>Number of sales we have completed with your help</div>
            <div className='bottom'>
            <div>{ counters.sales || 0 }</div>
                <img alt="Sales" src="assets/layout/images/ico-sales.png"/>
            </div>
        </div>
    </div>
</div>);
}