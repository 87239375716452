import React, { useState } from "react";
import {CardItemDetail} from '../CardItemDetail/CardItemDetail.component';
import { SalesPipelineService } from "../../services/SalesPipelineService";

// style
import "./CardItem.style.css";

export const CardItem = ({ id, name, trackCode, date, value, status, stage, disposition, showValue, showDisposition, refreshPipeline, job, config, consultants}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

  const handleDialogClick = async () => {
    await setLoading(true);
		const service = new SalesPipelineService();
    const _items = await service.loadDialogData(id);
    await setItems(_items);
    await setOpen(true)
    setLoading(false);
  }

  return (
    <div className="sp_card-item-wrapper" style={{backgroundColor: (job.escalation || {}).escalated ? 'red' : job.age >= 120 ? '#ffffff' : '#ffffff'}}>
      <div
        className="sp_card-item-info"
        style={{
          borderLeft: `4px solid ${
            (job.escalation || {}).escalated ? 'red' : 
            job.age >= 120 ? '#ffffff' : //'yellow' : 
              status === "danger" ? "#ffffff" //#f07171
              : status === "warning" ? "#ffffff" //ede74e
              : status === "normal" ? "#ffffff"
                : "#ffffff"
          }`,
          borderTopLeftRadius: window.innerWidth <= 768 ? 0 : "4px",
          borderBottomLeftRadius: window.innerWidth <= 768 ? 0 : "4px"
        }}
      >
        <h3 style={{ color: (job.escalation || {}).escalated ? 'white' : ''}}>{name}</h3>
        <h4 style={{ color: (job.escalation || {}).escalated ? 'white' : ''}}>{trackCode}</h4>
        {showValue === true ? ( <span>{value} Kwz</span> ): <span>&nbsp;</span>}
        { (date || "") !== "" ? 
          <span style={{ color: (job.escalation || {}).escalated ? 'white' : ''}} dangerouslySetInnerHTML={{__html: date === "1900-01-01" ? <>Invalid date</> : config.dateFormatFunction ? config.dateFormatFunction(date) : date}}></span> 
          : <span style={{ color: (job.escalation || {}).escalated ? 'white' : ''}}>{ config.hideDate ? <>&nbsp;</> : <>'{config.dateFieldName}' not set</>}</span>}
        <p style={{ color: (job.escalation || {}).escalated ? 'white' : ''}}>EC: {job.ec_name === "Unknown" ? (consultants.find(c => c.id === job.ec_email) || { name: job.ec_email}).name : job.ec_name}</p>
      </div>
      <div className="card-item-actions">
        <i className="pi pi-external-link" onClick={() => handleDialogClick()} style={{ color: (job.escalation || {}).escalated ? 'white' : ''}}></i>
        {status === "fail" && <i className="bx bx-error"></i>}
      </div>
      {open === true ? 
      <CardItemDetail
        id={id}
        trackCode={trackCode}
        name={name}
        value={value}
        stage={stage}
        date={date}
        isVisible={open}
        loadDialogData={loading}
        dialogData={items}
        closeModal={() => setOpen(false)}
        reloadDialogData={() => handleDialogClick()}
        disposition={disposition}
        showDisposition={showDisposition}
        refreshPipeline={refreshPipeline}
        job={job}
        config={config}
        consultants={consultants}
      />
      : null
    }
    </div>
  );
};
