
import { Card } from "../components/Card/Card.component";
import '../assets/css/sales-pipeline/desktop-large.css';
import '../assets/css/sales-pipeline/mobile.css';
import { useState, useEffect, useRef } from "react";
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { SalesPipelineService } from '../services/SalesPipelineService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from "moment";

export default function SalesPipeline() {

    const PRE_SALE = 'PRE-SALE';
    const POST_SALE = 'POST-SALE';

    const [section, setSection] = useState(PRE_SALE)
    const [currentIndex, setCurrentIndex] = useState(1);

    let hiddenItems = JSON.parse(localStorage.getItem('hiddenItems'));
    const initPreSales = []
    const initPostSales = []
    const [hiddenPreSalesList, setHiddenPreSalesList] = useState(initPreSales);
    const [hiddenPostSalesList, setHiddenPostSalesList] = useState(initPostSales);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [selectedDashboardMarket, setSelectedDashboardMarket] = useState(null);
    const [selectedDashboardTeam, setSelectedDashboardTeam] = useState(null);
    const [selectedDashboardConsultant, setSelectedDashboardConsultant] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [loadingDashboard, setLoadingDashboard] = useState(false);

    useEffect(() => {
        loadInitialData();

        if (hiddenItems && hiddenItems[PRE_SALE] && hiddenItems[PRE_SALE].length > 0) {
            hiddenPreSalesList.push(...hiddenItems[PRE_SALE])
            let uniquePre = [...new Set(hiddenPreSalesList)];
            setHiddenPreSalesList(uniquePre)
        }
        if (hiddenItems && hiddenItems[POST_SALE] && hiddenItems[POST_SALE].length > 0) {
            hiddenPostSalesList.push(...hiddenItems[POST_SALE])
            let uniquePost = [...new Set(hiddenPostSalesList)];
            setHiddenPostSalesList(uniquePost)
        }
    }, [setSection]);

    const loadInitialData = async () => {

        const service = new SalesPipelineService();
        const _data = await service.loadInitialData();
        setData(_data);

        setLoading(false);
    }

    const formatDateTime = (str) => {
        return "<div>Date: " + moment(str).utcOffset('-0700').format('MM/DD/YY') + "</div><div>Time: " + moment(str).utcOffset('-0700').format('hh:mma') + "</div>"
    }

    const formatDate = (str) => {
        return "<div>Date: " + moment(str).utcOffset('-0700').format('MM/DD/YY') + "</div>"
    }

    const formatStringDate = (str) => {

        const dateParts = str.split("-")
        if(dateParts.length === 3){
            return `<div>Date: ${dateParts[1]}/${dateParts[2]}/${dateParts[0].substring(2)}</div>`
        }

        return str;
    }

    const config = {
        'PRE-SALE': [
            { id: 'Appointment', index: 1, showValue: false, showDisposition: true, dateFieldName: "Consultation Date", maxAge: 3, ageField: "date", dateFormatFunction: formatDateTime },
            { id: 'Re-Schedule', index: 2, showValue: false, showDisposition: true, dateFieldName: "Consultation Date", maxAge: 3, ageField: "creation_date", dateFormatFunction: formatDate },
            { id: 'Demo Completed', index: 3, showValue: false, showDisposition: true, dateFieldName: "Consultation Date", maxAge: 3, ageField: "creation_date", dateFormatFunction: formatDate },
            { id: 'Closed Lost', index: 4, showValue: false, showDisposition: true, dateFieldName: "Consultation Date", maxAge: 3, ageField: "creation_date", dateFormatFunction: formatDate },
            { id: 'Follow Up (Long Term)', index: 5, showValue: false, showDisposition: true, dateFieldName: "Consultation Date", maxAge: 3, ageField: "creation_date", dateFormatFunction: formatDate },
            { id: 'Prelim Sent', index: 6, showValue: true, showDisposition: true, dateFieldName: "Preliminary Agreement Sent", maxAge: 12, ageField: "date", dateFormatFunction: formatStringDate }
        ],
        'POST-SALE': [
            { id: 'Resolution', index: 1, showValue: true, dangerThreshold: 5, warningThreshold: 5, dateFieldName: "State Changed", dateFormatFunction: formatStringDate },
            { id: 'Home Assessment', index: 2, showValue: true, dangerThreshold: 5, warningThreshold: 5, dateFieldName: "Preliminary Agreement Signed", dateFormatFunction: formatStringDate },
            { id: 'Design and Engineering', index: 3, showValue: true, dangerThreshold: 3, warningThreshold: 3, dateFieldName: "Stage Changed", dateFormatFunction: formatStringDate },
            { id: 'Permits and Materials', index: 4, showValue: true, dangerThreshold: 3, warningThreshold: 3, dateFieldName: "Stage Changed", dateFormatFunction: formatStringDate },
            { id: 'Installation', index: 5, showValue: true, dangerThreshold: 14, warningThreshold: 14, dateFieldName: "Stage Changed", dateFormatFunction: formatStringDate },
            { id: 'Inspection', index: 6, showValue: true, dangerThreshold: 5, warningThreshold: 5, dateFieldName: "Stage Changed", dateFormatFunction: formatStringDate },
            { id: 'Activation', index: 7, showValue: true, dangerThreshold: 30, warningThreshold: 30, dateFieldName: "Stage Changed", hideDate: false },
            { id: 'Activated', index: 7, showValue: true, dangerThreshold: 30, warningThreshold: 30, dateFieldName: "Stage Changed", hideDate: false, maxAge: 12, ageField: "date" },
        ],
    }

    const changeSection = (id) => {
        setCurrentIndex(1);
        setSection(id)
    }

    const handleHideColumns = async (id) => {
        if (id !== null) {
            if (section === PRE_SALE) {
                hiddenPreSalesList.push(...hiddenPreSalesList, id)
                let uniquePre = [...new Set(hiddenPreSalesList)];
                setHiddenPreSalesList(uniquePre)
            }
            else if (section === POST_SALE) {
                hiddenPostSalesList.push(...hiddenPostSalesList, id)
                let uniquePost = [...new Set(hiddenPostSalesList)];
                setHiddenPostSalesList(uniquePost)
            }
            let uniquePreSale = [...new Set(hiddenPreSalesList)];
            let uniquePostSale = [...new Set(hiddenPostSalesList)];

            let finalArray = {
                [PRE_SALE]: uniquePreSale,
                [POST_SALE]: uniquePostSale,
            }
            await localStorage.setItem('hiddenItems', JSON.stringify(finalArray));
        } else {
            if (section === PRE_SALE) {
                setHiddenPreSalesList(hiddenPreSalesList => [...hiddenPreSalesList])
            }
            else if (section === POST_SALE) {
                setHiddenPostSalesList(hiddenPostSalesList => [...hiddenPostSalesList])
            }
            let finalArray = {
                [PRE_SALE]: hiddenPreSalesList,
                [POST_SALE]: hiddenPostSalesList,
            }
            localStorage.setItem('hiddenItems', JSON.stringify(finalArray));
            hiddenItems = JSON.parse(localStorage.getItem('hiddenItems'));
        }
    }

    const hiddenItemOptionTemplate = (option) => {
        return (
            <div className="hidden-item-options">
                <span><i className={`pi pi-eye`}></i></span> {" "}
                {" "}<span>{option}</span>
            </div>
        );
    }
    const clearSelectedHiddenItem = async (option) => {
        if (section === PRE_SALE) {
            let index = hiddenPreSalesList.indexOf(option)
            let numberOfElementToRemove = 1;
            if (index !== -1) { hiddenPreSalesList.splice(index, numberOfElementToRemove) }
            await setHiddenPreSalesList(hiddenPreSalesList);
        }
        if (section === POST_SALE) {
            let index = hiddenPostSalesList.indexOf(option)
            let numberOfElementToRemove = 1;
            if (index !== -1) { hiddenPostSalesList.splice(index, numberOfElementToRemove) }
            await setHiddenPostSalesList(hiddenPostSalesList);
        }
        handleHideColumns(null);
    }

    const filterDashboard = async (field, newValue) => {

        const filter = {
            market: selectedDashboardMarket,
            consultant: selectedDashboardConsultant,
            team: selectedDashboardTeam,
        };

        if (field === "market") {
            setSelectedDashboardMarket(newValue);
            filter.market = newValue;
        }
        else if (field === "team") {
            setSelectedDashboardTeam(newValue);
            setSelectedDashboardConsultant(null);
            filter.team = newValue;
            filter.consultant = null;
        }
        else if (field === "consultant") {
            setSelectedDashboardConsultant(newValue);
            filter.consultant = newValue;
        }
        setSelectedFilters(filter);
        setLoadingDashboard(true);
    }

    const refreshPipeline = async () => {
        const filter = {
            market: selectedDashboardMarket,
            consultant: selectedDashboardConsultant,
            team: selectedDashboardTeam,
        };
        setSelectedFilters(filter);
        setLoadingDashboard(true);
    }

    const loadingSymbol = () => <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressSpinner /></div>

    const divRef = useRef(null)
    const executeScroll = (newIndex) => {
        setCurrentIndex(newIndex);
        divRef.current.scroll({ left: (newIndex-1)*divRef.current.offsetWidth, behavior: 'smooth' })
    }

    return (
        <div className='salespipeline'>

            <>
                {(loading) && loadingSymbol()}
                {!loading && <>
                    <div className='filters desktop'>
                        <span className="title">
                            <RadioButton value={PRE_SALE} name="preSaleOpt" onChange={(e) => changeSection(e.value)} checked={section === PRE_SALE} />
                        </span>
                        <span className="title">Pre-Sales</span>
                        <span className="title">
                            <RadioButton value={POST_SALE} name="postSaleOpt" onChange={(e) => changeSection(e.value)} checked={section === POST_SALE} />
                        </span>
                        <span className="title">Post-Sales</span>
                        
                        <Divider layout="vertical" />
                        
                        <span className="title">
                            <span className='filters-dropdown'><Dropdown showClear={true} placeholder='Market' value={selectedDashboardMarket} options={(data.markets || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('market', e.value)}></Dropdown></span>
                            {(data.teams || []).length > 1 &&
                                <span className='filters-dropdown'><Dropdown showClear={true} placeholder='Team' value={selectedDashboardTeam} options={(data.teams || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('team', e.value)} resetFilterOnHide={true} optionLabel="label" filter filterBy="label"></Dropdown></span>
                            }
                            {(data.consultants || []).length > 1 &&
                                <span className='filters-dropdown'><Dropdown showClear={true} placeholder="Energy Consultant" value={selectedDashboardConsultant} options={(data.consultants || []).filter(c => (selectedDashboardTeam || "") === "" ? true : (c.teams || []).includes(selectedDashboardTeam) ).map(m => ({ label: `${m.name} (${m.id})`, value: m.id }))} onChange={(e) => filterDashboard('consultant', e.value)} optionLabel="label" filter filterBy="label" /></span>                        
                            }
                        </span>
                        
                        {((section === PRE_SALE && (hiddenPreSalesList && hiddenPreSalesList.length > 0)) ||
                            (section === POST_SALE && (hiddenPostSalesList && hiddenPostSalesList.length > 0))) ?
                            <span className="title" style={{
                                display: (
                                    ((section === PRE_SALE && hiddenPreSalesList.length > 0) || (section === PRE_SALE && (hiddenItems && hiddenItems[PRE_SALE] && hiddenItems[PRE_SALE].length > 0))) ||
                                    ((section === POST_SALE && hiddenPostSalesList.length > 0) || (section === POST_SALE && (hiddenItems && hiddenItems[POST_SALE] && hiddenItems[POST_SALE].length > 0)))
                                ) ? '' : 'none'
                            }}>
                                <Divider layout="vertical" />
                                <span className='filters-dropdown'><Dropdown showClear={true} placeholder='Hidden Columns' options={section === PRE_SALE ? hiddenPreSalesList : section === POST_SALE ? hiddenPostSalesList : []} onChange={(e) => clearSelectedHiddenItem(e.value)} itemTemplate={hiddenItemOptionTemplate}></Dropdown></span>
                            </span>
                            : null}
                    </div>
                    <div className='filters mobile'>
                        <span onClick={(e) => changeSection(PRE_SALE)} className={`title ${section === PRE_SALE ? 'active' : ''}`}>Pre-Sales</span>
                        <span onClick={(e) => changeSection(POST_SALE)} className={`title ${section === POST_SALE ? 'active' : ''}`}>Post-Sales</span>
                    </div>
                    <div className='hiddenItems'>
                        <Dropdown style={{ width: '100%' }} showClear={true} placeholder='Market' value={selectedDashboardMarket} options={(data.markets || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('market', e.value)}></Dropdown>
                        {(data.teams || []).length > 1 &&
                            <Dropdown style={{ width: '100%' }} showClear={true} placeholder='Team' value={selectedDashboardTeam} options={(data.teams || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('team', e.value)} resetFilterOnHide={true} optionLabel="label" filter filterBy="label"></Dropdown>
                        }
                        {(data.consultants || []).length > 1 &&
                            <Dropdown style={{ width: '100%' }} showClear={true} placeholder="Energy Consultant" value={selectedDashboardConsultant} options={(data.consultants || []).filter(c => (selectedDashboardTeam || "") === "" ? true : (c.teams || []).includes(selectedDashboardTeam) ).map(m => ({ label: `${m.name} (${m.id})`, value: m.id }))} onChange={(e) => filterDashboard('consultant', e.value)} optionLabel="label" filter filterBy="label" />
                        }
                    </div>
                    {((section === PRE_SALE && (hiddenPreSalesList && hiddenPreSalesList.length > 0)) ||
                        (section === POST_SALE && (hiddenPostSalesList && hiddenPostSalesList.length > 0))) ?
                        <div className="hiddenItems" style={{
                            display: (
                                ((section === PRE_SALE && hiddenPreSalesList.length > 0) || (section === PRE_SALE && (hiddenItems && hiddenItems[PRE_SALE] && hiddenItems[PRE_SALE].length > 0))) ||
                                ((section === POST_SALE && hiddenPostSalesList.length > 0) || (section === POST_SALE && (hiddenItems && hiddenItems[POST_SALE] && hiddenItems[POST_SALE].length > 0)))
                            ) ? '' : 'none'
                        }}>
                        <Dropdown style={{ width: '100%' }} showClear={true} placeholder='Hidden Columns' options={section === PRE_SALE ? hiddenPreSalesList : section === POST_SALE ? hiddenPostSalesList : []} onChange={(e) => clearSelectedHiddenItem(e.value)} itemTemplate={hiddenItemOptionTemplate}></Dropdown>
                        </div>
                        : null}
                    {section === PRE_SALE &&
                        <div className='columns' ref={divRef}>
                            {config[section].map(i =>
                                <Card
                                    section={section}
                                    config={i}
                                    hiddenItemsList={hiddenPreSalesList}
                                    active={i.index === currentIndex}
                                    first={i.index === 1}
                                    last={i.index === config[section].length}
                                    navigate={(diff) => executeScroll(currentIndex + diff)}
                                    handleHideColumns={(id) => handleHideColumns(id)}
                                    filters={selectedFilters}
                                    loadingDashboard={loadingDashboard}
                                    loadingDashboardStop={() => setLoadingDashboard(false)}
                                    refreshPipeline={refreshPipeline}
                                    consultants={data.consultants}
                                />
                            )}
                        </div>
                    }
                    {section === POST_SALE &&
                        <div className='columns' ref={divRef}>
                            {config[section].map(i =>
                                <Card
                                    section={section}
                                    config={i}
                                    hiddenItemsList={hiddenPostSalesList}
                                    active={i.index === currentIndex}
                                    first={i.index === 1}
                                    last={i.index === config[section].length}
                                    navigate={(diff) => executeScroll(currentIndex + diff)}
                                    handleHideColumns={(id) => handleHideColumns(id)}
                                    filters={selectedFilters}
                                    loadingDashboard={loadingDashboard}
                                    loadingDashboardStop={() => setLoadingDashboard(false)}
                                    refreshPipeline={refreshPipeline}
                                    consultants={data.consultants}
                                />)
                            }
                        </div>
                    }
                </>}
            </>

        </div>
    )
}