import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { AuthorizationService } from '../services/AuthorizationService';

export function Header(props) {

    const menu = useRef(null);
    const [user, setUser] = useState(props.user);
    const authorizationService = new AuthorizationService();
    
    useEffect(() => {
        setUser(props.user);
    });

    const logout = () => {
        props.onLogout();
        setUser(null);
    }

    // let items = [
    //     {
    //         label: 'Options',
    //         items: [
    //             { 
    //                 label: 'Home', 
    //                 icon: 'pi pi-home',
    //                 command:()=> { 
    //                     { window.location.hash="/"; }
    //                 }
    //             },
    //         ]
    //     },
    //     {
    //         separator:true
    //     },
    //     {
    //         label: 'Log Out', 
    //         icon: 'pi pi-sign-out', 
    //         command: logout
    //     }
    // ]
    let items = [];

    /*
    if(authorizationService.can(AuthorizationService.Permissions.ACCESS_SALES_PIPELINE)){
        items[0].items.splice(1, 0, { 
            label: 'Sales Pipeline', 
            icon: 'pi pi-chart-line',
            command:()=> { 
                { window.location.hash="/sales-pipeline"; }
            }
        },);
    }

    if(authorizationService.can(AuthorizationService.Permissions.ACCESS_SALES_DASHBOARD)){
        items[0].items.splice(1, 0, { 
            label: 'Sales Dashboard', 
            icon: 'pi pi-chart-line',
            command:()=> { 
                { window.location.hash="/sales-dashboard"; }
            }
        },);
    }

    if(authorizationService.can(AuthorizationService.Permissions.ACCESS_REFERRAL_PROGRAM)){
        items[0].items.splice(1, 0, { 
            label: 'Create Referrals', 
            icon: 'pi pi-user-plus',
            command:()=> { 
                { window.location.hash="/referral-form"; }
            }
        },);

        items[0].items.splice(1, 0, { 
            label: 'My Referrals', 
            icon: 'pi pi-users',
            command:()=> { 
                { window.location.hash="/referral-list"; }
            }
        },);
    }
    */

    return <div className="header" style={window.location.hash === "#/" ? { position: 'fixed'} : {}}>
        <div className='logo'>
            <img id="logo" alt="Elevation Solar" src="assets/layout/images/logo-new.png"/>
        </div>
        { user &&  <>
            <div className='user-info'>
                <span className='title'>Hello</span>
                <span className='name'>{(user.name || "").split(" ")[0] || ""}</span>
                <div className='profile-image' onClick={() =>window.location.href = "/"}>
                    <i className='pi pi-home'></i>
                </div>
                <div className='logout-image' onClick={() => logout()}>
                    <i className='pi pi-sign-out'></i>
                </div>
            </div>
        </>}
    </div>
}   