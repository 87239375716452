import { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

import '../assets/css/sales-dashboard/desktop-large.css';
import '../assets/css/sales-dashboard/mobile.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SalesDashboardService } from '../services/SalesDashboardService';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function SalesDashboard() {

    const metricIds = {
        MEETINGS: "MEETINGS",
        APPOINTMENTS: "APPOINTMENTS",
        DEMOS: "DEMOS",
        SOLAR_SALES: "SOLAR_SALES",
        COMMISSIONS: "COMMISSIONS",
        SOLAR_INSTALATIONS: "SOLAR_INSTALATIONS",
        SOLAR_ACTIVATIONS: "SOLAR_ACTIVATIONS",
        // CURB_SALES: "CURB_SALES",
        // ENERGY_EFFICIENCY_SALES: "ENERGY_EFFICIENCY_SALES"
    }

    const [years, setYears] = useState([]);

    const [metrics, setMetrics] = useState([
        { id: metricIds.MEETINGS, index: 0, label: "Appointments", color: '#884000', fontColor: '#fff', checked: true },
        { id: metricIds.DEMOS, index: 1, label: "Demos", color: '#e86d00', fontColor: '#06285e', checked: true },
        { id: metricIds.SOLAR_SALES, index: 2, label: "Solar Sales", color: '#f4a636', fontColor: '#06285e', checked: true },
        { id: metricIds.COMMISSIONS, index: 3, label: "Commissions Cleared", color: '#ede74e', fontColor: '#06285e', checked: true },
        { id: metricIds.SOLAR_INSTALATIONS, index: 4, label: "Solar Installations", color: '#80c0ff', fontColor: '#06285e', checked: true },
        { id: metricIds.SOLAR_ACTIVATIONS, index: 5, label: "Solar Activations", color: '#81d553', fontColor: '#06285e', checked: true }
    ]);

    const [loading, setLoading] = useState(true);
    const [loadingRanking, setLoadingRanking] = useState(false);
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [data, setData] = useState({});
    const [compiledData, setCompiledData] = useState({});

    const [selectedDashboardYear, setSelectedDashboardYear] = useState((new Date()).getFullYear());
    const [selectedDashboardMarket, setSelectedDashboardMarket] = useState(null);
    const [selectedDashboardTeam, setSelectedDashboardTeam] = useState(null);
    const [selectedDashboardConsultant, setSelectedDashboardConsultant] = useState(null);
    const [selectedDashboardLeadSource, setSelectedDashboardLeadSource] = useState(null);
    const [selectedDashboardLeadSubSource, setSelectedDashboardLeadSubSource] = useState(null);

    const [selectedRankingYear, setSelectedRankingYear] = useState((new Date()).getFullYear());
    const [selectedRankingMarket, setSelectedRankingMarket] = useState(null);
    const [selectedRankingOrder, setSelectedRankingOrder] = useState(null);
    const [selectedRankingSeller, setSelectedRankingSeller] = useState(null);
    const [rankingStartPosition, setRankingStartPosition] = useState(1);

    useEffect(() => {

        const _years = []
        const currentYear = (new Date()).getFullYear();
        for(let i = currentYear; _years.length < 10; i--){
            _years.push({ label: i === currentYear ? 'This Year' : i === currentYear - 1 ? 'Last Year' : i, value: i});
        }
        setYears(_years);

        loadInitialData();
    }, []);

    const loadInitialData = async () => {

        const service = new SalesDashboardService();
        const _data = await service.loadInitialData();
        setData(_data);

        compileData(_data, metrics);
        setLoading(false);
    }

    const filterDashboard = async (field, newValue) => {

        setLoadingDashboard(true);
        const filter = {
            market: selectedDashboardMarket,
            consultant: selectedDashboardConsultant,
            year: selectedDashboardYear,
            lead_source: selectedDashboardLeadSource,
            lead_subsource: selectedDashboardLeadSubSource
        };

        if(field === "market") {
            setSelectedDashboardMarket(newValue);
            filter.market = newValue;
        }
        else if(field === "team") 
        {
            setSelectedDashboardTeam(newValue);
            filter.team = newValue;
        }
        else if(field === "consultant") 
        {
            setSelectedDashboardConsultant(newValue);
            filter.consultant = newValue;
        }
        if(field === "year")
        {
            setSelectedDashboardYear(newValue);
            filter.year = newValue;
        }
        if(field === "leadSource")
        {
            setSelectedDashboardLeadSource(newValue);
            filter.lead_source = newValue;
        }
        if(field === "leadSubSource")
        {
            setSelectedDashboardLeadSubSource(newValue);
            filter.lead_subsource = newValue;
        }

        const service = new SalesDashboardService();
        const dashboardData = await service.filterDashboard(filter);
        const _data = Object.assign(data, dashboardData);
        setData(_data);
        compileData(_data, metrics);
        setLoadingDashboard(false);
    }

    const compileData = (p_data, p_metrics) => {
        const _compiledData = {
            labels: p_data.periodical.categories,
            datasets: p_metrics.filter(m => m.checked && !m.disabled).map(metric =>
            ({
                label: metric.label,
                data: p_data.periodical[metric.id],
                borderColor: metric.color,
                backgroundColor: metric.color,
                pointStyle: 'circle',
                pointRadius: 7,
                pointHoverRadius: 10,
                borderDash: [5, 5]
            })
            )
        }

        setCompiledData(_compiledData);
    }

    const filterRanking = async (field, newValue) => {

        setLoadingRanking(true);
        const filter = {
            market: selectedRankingMarket,
            metric: selectedRankingOrder,
            year: selectedRankingYear
        };

        if(field === "market") {
            setSelectedRankingMarket(newValue);
            filter.market = newValue;
        }
        else if(field === "order") 
        {
            setSelectedRankingOrder(newValue);
            filter.metric = newValue;
        }
        if(field === "year")
        {
            setSelectedRankingYear(newValue);
            filter.year = newValue;
        }

        const service = new SalesDashboardService();
        const rankingData = await service.filterRanking(filter);

        console.log("rankingData", rankingData)
        const _data = Object.assign(data, rankingData);
        console.log("_data", _data)
        setData(_data);

        //compileData(_data, metrics);
        setLoadingRanking(false);
    }

    const filterRankingConsultant = (id) => {
        console.log(id);
        const position = data.ranking.find(r => r.id === id).position;
        console.log("position", position)

        if(position === 1){
            setRankingStartPosition(1);
        }
        else {
            setRankingStartPosition(position - 1);
        }

        setSelectedRankingSeller(id);
    }


    const toggleMetric = (metric) => {
        const _metrics = [].concat(metrics);
        _metrics.find(m => m.id === metric.id).checked = !_metrics.find(m => m.id === metric.id).checked;
        setMetrics(_metrics);
        compileData(data, _metrics);
    }

    const loadingSymbol = () => <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ProgressSpinner/></div>
    
    return (
        <div className='salesdashboard'>

            <div className='chart-panel'>
                { (loading) && loadingSymbol() }
                { !loading && <>
                <div className='chart-header'>
                    <div className='title'>
                        <span>Dashboard</span>
                        <i className="pi pi-external-link" style={{'fontSize': '15px', marginLeft: 5, display: 'none'}}></i>
                    </div>
                    <div className='filters desktop'>
                        <div className='filters-dropdown'><Dropdown showClear={true} placeholder='Market' value={selectedDashboardMarket} options={(data.markets || []).map(m =>({label: m.name, value: m.id}))} onChange={(e) => filterDashboard('market', e.value)}></Dropdown></div>
                            { (data.teams || []).length > 1 && 
                            <div className='filters-dropdown'><Dropdown showClear={true} placeholder='Team' value={selectedDashboardTeam} options={(data.teams || []).map(m =>({label: m.name, value: m.id}))} onChange={(e) => filterDashboard('team', e.value)} resetFilterOnHide={true} optionLabel="label" filter filterBy="label"></Dropdown></div>
                            }
                            <div className='filters-dropdown'><Dropdown showClear={true} placeholder="Energy Consultant" value={selectedDashboardConsultant} options={(data.consultants || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('consultant', e.value)} optionLabel="label" filter filterBy="label"/></div>
                            <div className='filters-dropdown'><Dropdown showClear={true} placeholder="Lead Source" value={selectedDashboardLeadSource} options={(data.leadSource || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('leadSource', e.value)} optionLabel="label" filter filterBy="label"/></div>
                            <div className='filters-dropdown'><Dropdown showClear={true} placeholder="Lead SubSource" value={selectedDashboardLeadSubSource} options={(data.leadSubSource || []).map(m => ({ label: m.name, value: m.id }))} onChange={(e) => filterDashboard('leadSubSource', e.value)} optionLabel="label" filter filterBy="label"/></div>
                            <div className='filters-divider'></div>
                            <div className='filters-dropdown'><Dropdown placeholder='Last Year' value={selectedDashboardYear} options={years} onChange={(e) => filterDashboard('year', e.value)}></Dropdown></div>
                        </div>
                        <div className='filters mobile'>
                        <div className='filters-dropdown'><Dropdown showClear={true} placeholder='Market' value={selectedDashboardMarket} options={(data.markets || []).map(m =>({label: m.name, value: m.id}))} onChange={(e) => filterDashboard('market', e.value)}></Dropdown></div>
                        <div className='filters-divider'></div>
                        <div className='filters-dropdown'><Dropdown placeholder='Last Year' value={selectedDashboardYear} options={years} onChange={(e) => filterDashboard('year', e.value)}></Dropdown></div>
                    </div>
                    <div className='filters mobile'>
                        { (data.teams || []).length > 1 && <>
                            <div className='filters-dropdown'><Dropdown showClear={true} placeholder='Team'  value={selectedDashboardTeam} options={(data.teams || []).map(m =>({label: m.name, value: m.id}))} onChange={(e) => filterDashboard('team', e.value)}></Dropdown></div>
                            <div className='filters-divider'></div>
                        </>}
                        <div className='filters-dropdown'><Dropdown showClear={true} placeholder='Energy Consultant'  value={selectedDashboardConsultant} options={(data.consultants || []).map(m =>({label: m.name, value: m.id}))} onChange={(e) => filterDashboard('consultant', e.value)}></Dropdown></div>
                    </div>
                </div>
                <div className='metrics-and-chart'>
                    <div className='metrics'>
                        { metrics.map(metric =>
                        <div key={metric.id} className={`metric ${metric.disabled ? 'disabled' : ''}`} style={{backgroundColor: metric.color }}>
                            <div className={`icon ${metric.disabled ? 'disabled' : ''}`} style={{backgroundColor: metric.color }}></div>
                            <div className={`content ${metric.disabled ? 'disabled' : ''}`}>
                                <div className={`value ${metric.disabled ? 'disabled' : ''}`} style={{ color: metric.fontColor }}>{ metric.disabled || !metric.checked ? '-' : loadingDashboard ? "..." : data.total[metric.id] }</div>
                                <div className={`label ${metric.disabled ? 'disabled' : ''}`} style={{ color: metric.fontColor }}>{ metric.label }</div>
                            </div>
                            <div className={`check ${metric.disabled ? 'disabled' : ''}`}
                                onClick={metric.disabled ? () => {} : () => toggleMetric(metric)}>{ !metric.disabled && metric.checked ? <i className="pi pi-check"></i> : <></> }</div>
                            </div>
                        )}

                    </div>
                    { (loadingDashboard) && loadingSymbol() }
                    { !loadingDashboard &&
                    <div className='chart'>
                        <Line 
                        data={compiledData}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false
                              }
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        color: '#06285e',
                                        fontFamily: 'Inter',
                                        fontSize: 13,
                                    },
                                    grid: {
                                        
                                        color: '#ebedef'
                                    }
                                },
                                y: {
                                    min: 0,
                                    ticks: {
                                        color: '#06285e',
                                        fontFamily: 'Inter',
                                        fontSize: 13
                                    },
                                    grid: {
                                        display: false,
                                        color: '#ebedef'
                                    },
                                    drawBorder: false,
                                    gridLines: {
                                        display: false,
                                      }
                                }
                            }

                          }}/>
                    </div>
                    }
                </div>
                </>}
            </div>

            <div className='ranking-and-messages-panel'>
                <div className='ranking-panel'>
                    { (loading) && loadingSymbol() }
                    { !loading && <>
                    <div className='title'>
                        <span>Rankings</span>
                        <i className="pi pi-external-link" style={{'fontSize': '15px', marginLeft: 5, display: 'none'}}></i>
                    </div>
                    <div className='filters'>
                        <div className='filters-dropdown' style={{width: '100%'}}><Dropdown placeholder='Sort by Sales' value={selectedRankingOrder} options={[
                                {label: 'Sort by Meetings', value: 'MEETINGS'},
                                {label: 'Sort by Sales', value: 'SALES'},
                                {label: 'Sort by Activations', value: 'ACTIVATIONS'}
                            ]}
                            onChange={(e) => filterRanking('order', e.value)}
                        ></Dropdown></div>
                    </div>
                    <div className='ranking'>
                        { (loadingRanking) && loadingSymbol() }
                        { !loadingRanking && <>
                        { data.ranking.filter(r => r.position >= rankingStartPosition && r.position <= rankingStartPosition+2).sort((a,b) => a.position > b.position ? 1 : -1).map(item => 
                            <div key={`ranking_${item.position}`} className={`ranking-item ${ item.position === 1 ? 'leader' : ''}`}>
                                <div className='position'>{item.position}</div>
                                <div className='name'>{item.name}</div>
                                <div className='score'>{item.score}</div>
                            </div>
                        )}
                        </>}
                    </div>
                    </>}
                </div>
                { 
                    <div className='messages-panel'>Message Center (Coming soon)</div> 
                }
            </div>

        </div>
    )

}