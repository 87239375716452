import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import * as _ from 'lodash';
import * as uuid from 'uuid';

import { AccountService } from '../services/AccountService';

export function Profile(props) {

    let history = useHistory();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [user, setUser] = useState();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(async () => {
        setUser(props.user);
        setLoading(false);
    }, []);
    
    const isDataValid = () => { 

        if((user.name || "") === "" || (user.phone || "") === ""){
            return false;
        }

        if((user.paymentConfig.config.option || "donation") === "cash"){

            if((user.paymentConfig.config.preferred || "") !== ""){

                if((user.paymentConfig.config.preferred || "") === "Paypal") {
                    if((user.paymentConfig.config.paypal.id_type || "") === ""){
                        return false;
                    }

                    if((user.paymentConfig.config.paypal.id || "") === ""){
                        return false;
                    }
                }

                if((user.paymentConfig.config.preferred || "") === "Venmo") {
                    if((user.paymentConfig.config.venmo.id || "") === ""){
                        return false;
                    }
                }
                
            }
        }

        if((user.paymentConfig.config.option || "donation") === "donation"){
            if(!user.paymentConfig.config.school){
                return false;
            }
        }

        return true;
    }

    const save = async () => {
        try 
        {
            setShowSuccessMessage(false);
            setSubmitted(true);
            if(isDataValid()){
                setSaving(true);
                const service = new AccountService();
                await service.updateProfile(user);
                setSaving(false);
                props.onUserUpdate(user);
                setSaved(true);
                history.push("/profile/saved")
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message ? error.message : error);
        }
    }

    const updateValue = (fieldName, value) => {
        const _user = Object.assign({}, user) 
        _.set(_user, fieldName, value);
        setUser(_user);
        //setShowSuccessMessage(false);
        setSubmitted(false);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const paymentProviders = [
        {label: 'Paypal', value: 'Paypal'},
        {label: 'Venmo', value: 'Venmo'}
    ];

    const paypalIdTypes = [
        {label: 'Email', value: 'EMAIL'},
        {label: 'Phone', value: 'PHONE'},
        {label: 'Paypal ID', value: 'PAYPAL_ID'}
    ];
    
    return <div className="screen home">
    
        { saved && <>
            <div className="form-box">
                <div className="main-title">Thank you for confirming your payment options</div>
                <div className="title">You can track your referrals and add more by logging into the Refer a Friend site any time.</div>
                <div className="buttons">    
                    <Button label="Track My Referrals" className="button" onClick={() => history.push("/")} />
                </div>
            </div>
        </>}
            
        { !saved && <>
            <div className="title">My Profile</div>
            <div className="form-box">
                { !loading && <>
                <div id="profile-small" className="p-grid">
                    <div className="p-col-12 column-value" style={{borderBottom: '0px'}}>Name</div>
                    <div className="p-col-12 formfieldcolumn" style={{paddingTop: '0px', textAlign: 'center'}}>
                        <InputText value={user.name || ""} onChange={(e) => updateValue('name', e.target.value)} />
                        { submitted && user.name === "" && <div className="user-error">Name is mandatory</div>}
                    </div>
                    <div className="p-col-12 column-value" style={{borderBottom: '0px', paddingTop: '0px'}}>Mobile</div>
                    <div className="p-col-12 formfieldcolumn" style={{paddingTop: '0px', textAlign: 'center'}}>
                        <InputMask autoClear={false} mask="(999) 999-9999" value={user.phone || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('phone', cleanPhone(e.value))}></InputMask>
                        { submitted && user.phone === "" && <div className="user-error">Phone is mandatory</div>}
                    </div>
                    <div className="p-col-12 column-value" style={{borderBottom: '0px', paddingTop: '0px'}}>Email</div>
                    <div className="p-col-12 formfieldcolumn" style={{paddingTop: '0px', textAlign: 'center'}}>
                    <InputText disabled tooltip="Email can't be changed" value={user.email || ""}/>
                    </div>
                </div>
                <div id="profile-large" className="p-grid">
                    <div className="p-col-4 column-title">Name</div>
                    <div className="p-col-4 column-title">Mobile</div>
                    <div className="p-col-4 column-title">Email</div>
                    <div className="p-col-4 formfieldcolumn">
                        <InputText value={user.name || ""} onChange={(e) => updateValue('name', e.target.value)} />
                        { submitted && user.name === "" && <div className="user-error">Name is mandatory</div>}
                    </div>
                    
                    <div className="p-col-4 formfieldcolumn">
                        <InputMask autoClear={false} mask="(999) 999-9999" value={user.phone || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('phone', cleanPhone(e.value))}></InputMask>
                        { submitted && user.phone === "" && <div className="user-error">Phone is mandatory</div>}
                    </div>
                    
                    <div className="p-col-4 formfieldcolumn">
                    <InputText disabled tooltip="Email can't be changed" value={user.email || ""}/>
                    </div>
                </div>
                
                <div className="buttons">    
                    <Button disabled={saving} label="Save" className="button" onClick={save} />
                </div>
                </>}
                { showSuccessMessage && <div className="buttons"><div className="success-message">Profile updated successfully</div></div>}
                { (saving || loading) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
            </div>
        </>}
    </div>
}