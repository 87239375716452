import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";

import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

import { ReferralService } from '../services/ReferralService';
import { ReferrerService } from '../services/ReferrerService';
import * as moment from 'moment';
import ReferralModal from '../components/ReferralModal';
import RightPanel from '../components/RightPanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';



export function ReferralsList(props) {

    const history = useHistory();
    const [referrals, setReferrals] = useState(props.user.referrals || []);
    const [referrers, setReferrers] = useState(props.user.referrers || []);
    const [loading, setLoading] = useState(true);
    const [openStatus, setOpenStatus] = useState({});
    const [modalOption, setModalOption] = useState("");

    const [activeTab, setactiveTab] = useState("REFERRALS");

    useEffect(() => {
        
        const fetchData = async () => {
            const referralService = new ReferralService();
            const referrerService = new ReferrerService();
            setReferrals(await referralService.list());
            const _referrals = await referrerService.list();
            setReferrers(_referrals);
            setLoading(false);
        }
        
        fetchData();
    }, []);

    const updateOpenStatus = (key, value) => {
        const _openStatus = Object.assign({}, openStatus);
        _openStatus[key] = value;
        setOpenStatus(_openStatus);
    }

    const updateReferrers = async () => {
        const referrerService = new ReferrerService();
        setReferrers(await referrerService.list());
    }

    const toast = useRef(null);
    const [resendingLink, setResendingLink] = useState(false);
    const resendInvite = async (id) => {
        try {
            setResendingLink(true);
            const referrerService = new ReferrerService();
            await referrerService.resendInvite(id);
            toast.current.show({severity:'success', summary: 'Success', detail:'Link re-sent', life: 3000});
            setResendingLink(false);
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Error', detail: error.message || error.toString(), life: 10000});
            setResendingLink(false);
        }
    }

    return <>

        { loading && <div className='left-panel empty loading'><ProgressSpinner/></div>}


        {
            modalOption !== "" &&
            <ReferralModal 
                option={modalOption} 
                onClose={() => setModalOption("")} 
                onUpdateReferrals={(referrals) => { setReferrals(referrals); }}
                onUpdateReferrers={() => updateReferrers()}
                ></ReferralModal>
        }
        
        { (referrals || []).length === 0 && !loading &&
            <div className='left-panel empty'>
                <div className='hello'>Hello {(props.user.name || "").split(" ")[0] || ""}!</div>
                <div className='description'>
                    Welcome to your Referral Program Dashboard. 
                    Looks like <span>you don’t have any referrals yet</span>.
                    Click the button below to start adding some!
                </div>
                <Button label="ADD REFERRALS         →" className="button" onClick={() => history.push("/referral-form")} />
            </div>
        }
            
        { (referrals || []).length > 0 && !loading && 
            <div className='left-panel list'>
                <Toast ref={toast} style={{zIndex: '10000'}} />
                <div className='title'>YOUR REFERRALS</div>
                <div className='desc'>View the status of your referrals and share Elevation with more friends.</div>
                <div className='tabs-container'>
                    <div className='tabs'>
                        <div className='tabs-header'>
                            <div className='tabs-spacer'>&nbsp;</div>
                            <div className={`tab${ activeTab === "REFERRALS" ? ' active' : ''}`} onClick={() => { setactiveTab('REFERRALS'); window.scrollTo(0, 0) }}>REFERRALS</div>
                            <div className={`tab${ activeTab !== "REFERRALS" ? ' active' : ''}`} onClick={() => { setactiveTab('REFERRERS'); window.scrollTo(0, 0) }}>REFERRERS</div>
                            <div className='tab-spacer'></div>
                            <div className='tabs-spacer'>&nbsp;</div>
                        </div>
                        <div className='tabs-content-container'>
                            <div className={`tabs-content${ activeTab === "REFERRALS" ? ' first' : ' second'}`}>
                                <div className={`tab-content${ activeTab === "REFERRALS" ? ' active' : ''}`}>
                                    <div className="referrals small">
                                        { (referrals || []).map(referral => 
                                            
                                            <React.Fragment key={referral.id}>
                                                <div className={`header ${openStatus[referral.id] ? 'open' : 'closed'}`} onClick={() => updateOpenStatus(referral.id, openStatus[referral.id] ? false: true)}>
                                                    <div className='name_status'>
                                                        <span>{referral.first_name} {referral.last_name}</span>
                                                        <span>{ ReferralService.getActivationStatus(referral, referral.payments) }</span>
                                                    </div>
                                                    <div className='icon'>
                                                        <img alt={openStatus[referral.id] ? 'Close' : 'Open'} src={`assets/layout/images/ico-arrow-${openStatus[referral.id] ? 'up' : 'down'}.png`}/>
                                                    </div>
                                                </div>
                                                <div className={`content ${openStatus[referral.id] ? 'open' : 'closed'}`}>
                                                    <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='title'>Mobile #</td>
                                                            <td className='value'>{referral.mobile}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Email</td>
                                                            <td className='value'>{referral.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Mtng. Status</td>
                                                            <td className='value'>{ ReferralService.getAppointmentStatus(referral, referral.payments) }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Act. Status</td>
                                                            <td className='value'>{ ReferralService.getActivationStatus(referral, referral.payments) }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Date</td>
                                                            <td className='value'>{moment(referral.created_on).format("MM/DD/YY")}</td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                    <div className="referrer">
                                                        <img alt='Referrer' src='/assets/layout/images/ico-referrer.png'/>
                                                        <div className="name">Referred by: <span>{referral.referrer.name}</span></div>
                                                    </div>
                                                </div> 
                                                
                                            </React.Fragment>
                                        )}
                                        <div className="buttons">    
                                            <Button label="Add More Referrals" className="button" onClick={() => setModalOption('REFERRALS')} />
                                        </div>
                                        { loading && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                                    </div>
                                    <div className="referrals large">       
                                    
                                        <table width={'100%'} cellSpacing="0">
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile #</th>
                                            <th>Email</th>
                                            <th>Meeting</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                        </tr>
                                        { 
                                            (referrals || []).map(referral => <React.Fragment key={referral.id}>
                                                    <tr className="values">
                                                        <td className="value name">{referral.first_name} {referral.last_name}</td>
                                                        <td className="value">{referral.mobile}</td>
                                                        <td className="value">{referral.email}</td>
                                                        <td className="value">{ ReferralService.getAppointmentStatus(referral, referral.payments) }</td>
                                                        <td className="value">{ ReferralService.getActivationStatus(referral, referral.payments) }</td>
                                                        <td className="value">{moment(referral.created_on).format("MM/DD/YY")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6} className="referrer-row">
                                                           <div className="referrer">
                                                                <img alt='Referrer' src='/assets/layout/images/ico-referrer.png'/>
                                                                <div className="name">Referred by: <span>{referral.referrer.name}</span></div>
                                                           </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        </tbody>
                                        </table>
                                        <div className="buttons">    
                                            <Button label="ADD MORE REFERRALS         →" className="button" onClick={() => setModalOption('REFERRALS')} />
                                        </div>
                                        { loading && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                                    </div>
                                </div>
                                <div className={`tab-content${ activeTab !== "REFERRALS" ? ' active' : ''}`}>
                                <div className="referrals small">
                                        { (referrers || []).map(referrer => 
                                            
                                            <React.Fragment key={referrer.id}>
                                                <div className={`header ${openStatus[referrer.id] ? 'open' : 'closed'}`} onClick={() => updateOpenStatus(referrer.id, openStatus[referrer.id] ? false: true)}>
                                                    <div className='name_status'>
                                                        <span>{referrer.name}</span>
                                                        { referrer.registered &&
                                                                <span style={{color: '#81d553'}}>Registration Complete</span>
                                                            }
                                                            { !referrer.registered && referrer.clicked && 
                                                                <span style={{color: '#ece64f'}}>Link clicked, registration pending</span>
                                                            }
                                                            { !referrer.registered && !referrer.clicked && 
                                                                <span style={{color: '#f15e5e'}}>Link not clicked</span>
                                                            }
                                                    </div>
                                                    <div className='icon'>
                                                        <img alt={openStatus[referrer.id] ? 'Close' : 'Open'} src={`assets/layout/images/ico-arrow-${openStatus[referrer.id] ? 'up' : 'down'}.png`}/>
                                                    </div>
                                                </div>
                                                <div className={`content ${openStatus[referrer.id] ? 'open' : 'closed'}`}>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <td className='title'>Mobile #</td>
                                                            <td className='value'>{referrer.mobile}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Email</td>
                                                            <td className='value'>{referrer.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'>Link Sent?</td>
                                                            <td className='value'>Yes</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='title'># of Referrals</td>
                                                            <td className='value'>{referrer.referralscount }</td>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                    
                                                    { referrer.registered &&
                                                        <div className="referrer">
                                                                <div className='image-green'><img alt='Registered' src='/assets/layout/images/ico-check.png'/></div>
                                                                <div className="name">Customer completed registration.</div>
                                                        </div>
                                                    }
                                                    { !referrer.registered && referrer.clicked && 
                                                        <div className="referrer">
                                                                <img alt='Clicked' src='/assets/layout/images/ico-referrer-status-yelllow.png'/>
                                                                <div className="name">Customer clicked the link but didn’t complete registration. { resendingLink ? <span>Re-sending link...</span> : <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => resendInvite(referrer.id)}>Send link again</span> }</div>
                                                        </div>
                                                    }
                                                    { !referrer.registered && !referrer.clicked && 
                                                        <div className="referrer">
                                                                <img alt='Not Clicked' src='/assets/layout/images/ico-referrer-status-red.png'/>
                                                                <div className="name">Customer hasn’t clicked the link yet. { resendingLink ? <span>Re-sending link...</span> : <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => resendInvite(referrer.id)}>Send link again</span> }</div>
                                                        </div>
                                                    }
                                                    
                                                </div> 
                                                
                                            </React.Fragment>
                                        )}
                                        <div className="buttons">    
                                            <Button label="Add More Referrers" className="button" onClick={() => setModalOption('REFERRERS')} />
                                        </div>
                                        { loading && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                                    </div>
                                    <div className="referrals large">       
                                    
                                        <table width={'100%'}>
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile #</th>
                                            <th>Email</th>
                                            <th>LINK SENT?</th>
                                            <th># OF REF.</th>
                                        </tr>
                                        { 
                                            (referrers || []).map(referrer => <React.Fragment key={referrer.id}>
                                                    <tr className="values">
                                                        <td className="value name">{referrer.name}</td>
                                                        <td className="value">{referrer.mobile}</td>
                                                        <td className="value">{referrer.email}</td>
                                                        <td className="value">Yes</td>
                                                        <td className="value">{referrer.referralscount }</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6} className="referrer-row">
                                                            { referrer.registered &&
                                                                <div className="referrer">
                                                                        <div className='image-green'><img alt='Registered' src='/assets/layout/images/ico-check.png'/></div>
                                                                        <div className="name">Customer completed registration.</div>
                                                                </div>
                                                            }
                                                            { !referrer.registered && referrer.clicked && 
                                                                <div className="referrer">
                                                                        <img alt='Clicked' src='/assets/layout/images/ico-referrer-status-yelllow.png'/>
                                                                        <div className="name">Customer clicked the link but didn’t complete registration. { resendingLink ? <span>Re-sending link...</span> : <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => resendInvite(referrer.id)}>Send link again</span> }</div>
                                                                </div>
                                                            }
                                                            { !referrer.registered && !referrer.clicked && 
                                                                <div className="referrer">
                                                                        <img alt="Not Click" src='/assets/layout/images/ico-referrer-status-red.png'/>
                                                                        <div className="name">Customer hasn’t clicked the link yet. { resendingLink ? <span>Re-sending link...</span> : <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={() => resendInvite(referrer.id)}>Send link again</span> }</div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        </tbody>
                                        </table>
                                        <div className="buttons">    
                                            <Button label="ADD MORE REFERRERS         →" className="button" onClick={() => setModalOption('REFERRERS')} />
                                        </div>
                                        { loading && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                                    </div>
                                </div>
                            </div>
                            <div className='tabs-content-spacer'>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        }
    
        { !loading && <RightPanel referrals={referrals || []}/> }
    </>
}