import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import { ProgressBar } from 'primereact/progressbar';

import { SignUpService } from '../services/SignUpService';


export function Welcome(props) {

    let history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [showAccountAlreadyActiveMessage, setShowAccountAlreadyActiveMessage] = useState(false);
    const [accountErrorMessage, setAccountErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);

    const [emailChecked, setEmailChecked] = useState(false);
    
    const [sendingCode, setSendingCode] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [checkingCode, setCheckingCode] = useState(false);
    const [codeChecked, setCodeChecked] = useState(false);
    
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [savingPassword, setSavingPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(null);

    const [signUpComplete, setSignUpComplete] = useState(false);

    const checkEmail = async () => 
    {
        try 
        {
            setSaving(true);
            setAccountErrorMessage("");
            setShowAccountAlreadyActiveMessage(false);
            const service = new SignUpService();
            await service.checkEmail(email);
            setEmailChecked(true);
            setSaving(false);
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 430){
                setShowAccountAlreadyActiveMessage(true);
            }
            else if(error.code && error.code === 400){
                setAccountErrorMessage(error.message);
            }
            else {
                alert(error);
            }            
        }
    }

    const sendCode = async () => {
        try {
            setCode("");
            setCodeError(false);
            setSendingCode(true);
            const service = new SignUpService();
            await service.sendToken(name, email);
            setSendingCode(false);
            setCodeSent(true);    
        } 
        catch (error) 
        {
            setSendingCode(false);
            setCodeSent(false);    
            console.log(error);
            window.alert(error.message || error);
        }
        
    }

    const checkCode = async () => {
        
        try {
            setCodeError(false);
            setCheckingCode(true);
            const service = new SignUpService();
            await service.checkToken(code);
            setCheckingCode(false);
            setCodeChecked(true); 
        } 
        catch (error) 
        {
            console.log(error);
            setCheckingCode(false);
            setCodeChecked(false);    
            if(error.code && error.code === 400){
                setCodeError(true);
            }
            else {
                window.alert(error.message || error);
            }
        }
    }

    const savePassword = async () => {
        setPasswordError(null)
        if(password !== passwordConfirmation){
            setPasswordError("Password confirmation does not match")
        }
        else {
            try {
                setSavingPassword(true);
                const service = new SignUpService();
                await service.savePassword(password);
                setSavingPassword(false);
                setSignUpComplete(true);
            } 
            catch (error) 
            {
                console.log(error);
                setSavingPassword(false);
                if(error.code && error.code === 400){
                    setPasswordError("Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.");
                }
                else {
                    window.alert(error.message || error);
                }
            }
        }
    }

    const getForm = () => {

        return <>
            { 
            !emailChecked && 
            <>
                <div className="form-box">
                    <div className="description" style={{marginBottom: '20px'}}>Register to add customer referrals, invite customers to refer their friends, and track the status of your referrals.</div>
                    <div className='fields'>
                        <div className="formfield">
                            <InputText 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Name" 
                            />
                        </div>
                        <div className="formfield">
                            <InputText 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Elevation Email" 
                            />
                        </div>
                    </div>
                    { showAccountAlreadyActiveMessage && <div className="fieldErrorMessage">An active account is already using this email address. Please&nbsp;<Link to={``}>log in</Link>.</div>}
                    { accountErrorMessage && <div className="fieldErrorMessage">{accountErrorMessage}</div>}
                    <div className="existing-account-message">
                        <span className="message"><a href="https://www.poweredbyelevation.com/terms" target="_blank" rel="noreferrer">Terms & Conditions</a></span> 
                    </div>
                    { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" /> }
                    { !saving && <>
                    <div className="buttons">
                        <Button disabled={name === "" || email === ""} label="Register →" className="button" onClick={checkEmail} />
                    </div>
                    <div className="existing-account-message">Already have an account? <Link to={``}>Log in</Link>.</div>
                    <div className="existing-account-message">Learn more about <a href="https://www.elevationsolar.com/refer-a-friend/" target="_blank" rel="noreferrer">Elevation's Refer a Friend program</a>.</div>
                    </>}
                </div>
            </>
        }
        { 
            emailChecked && 
            <>
                <div className="form-box">
                    { 
                        !codeSent && 
                        <>
                            <div className="description">To verify your email address, we will send an email to <b>{ email }</b> with a verification code.</div>
                            <div className="buttons">    
                                <Button disabled={sendingCode} label="Ok, Send Me the Code" className="button" onClick={sendCode} />
                            </div>
                            { 
                                sendingCode && 
                                <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />
                            }
                        </>
                    }
                    { 
                        codeSent && !codeChecked && 
                        <>
                            <div className="description">To verify your email address, we sent an email to <b>{ email }</b> with a verification code. Please enter your code here.</div>
                            <div className='fields'>
                                <div className="formfield">
                                    <InputText 
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        placeholder="Code" 
                                    />
                                </div>
                            </div>
                            { 
                                codeError && 
                                <div className="fieldErrorMessage">Invalid verification code</div> 
                            }
                            <div className="buttons">    
                                <Button disabled={sendingCode || checkingCode} label="Verify" className="button" onClick={checkCode} />
                                <Button disabled={sendingCode || checkingCode} label="Send again" className="button" onClick={sendCode} />
                            </div>
                            { 
                                (sendingCode || checkingCode) && 
                                <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />
                            }
                        </>
                    }
                    { 
                        codeChecked && !signUpComplete && 
                        <>
                            <div className="description">Please create a password so you can log in, track the progress of your referrals, and send invitations to customers to refer their friends.</div>
                            <div className='fields'>
                            <div className="formfield">
                            <div className='password-form-field' style={{width: '80%'}}>
                                <Password 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    placeholder="Password"
                                    style={{width: '100%'}}
                                    inputStyle={{width: '100%'}}
                                    promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                    strongLabel="We are good to go."
                                    toggleMask={true}
                                />
                                </div>
                            </div>
                            <div className="formfield">
                                <div className='password-form-field' style={{width: '80%'}}>
                                <Password 
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    placeholder="Re-Type" 
                                    feedback={false}
                                    toggleMask={true}
                                    style={{width: '100%'}}
                                    inputStyle={{width: '100%'}}
                                />
                                </div>
                            </div>
                            </div>
                            { 
                                passwordError && 
                                <div className="fieldErrorMessage">{passwordError}</div> 
                            }
                            <div className="buttons">    
                                <Button disabled={savingPassword} label="Complete My Registration" className="button" onClick={savePassword} />
                            </div>
                            { 
                                (savingPassword) && 
                                <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />
                            }
                        </>
                    }
                    { 
                        signUpComplete && 
                        <>
                            <div className="description">Please log in with your new credentials.</div>
                            <div className="buttons">    
                                <Button label="Back to Login" className="button" onClick={() => history.push("/")} />
                            </div>
                        </>
                    }
                </div>
            </>}
        </>;
    }

    return <>
    
    <div className="screen login">

        <div className="login-left-panel">
                <div className='logo'>
                    <img id="logo" alt="Elevation Solar" src="assets/layout/images/logo.png"/>
                </div>
                <div className='title'>Welcome to<br/>Elevation<br/>Sales Dashboard</div>
                <div className='sub-title'>For Elevation Sales Team</div>
                <div className="login-right-panel mobile">
                    {getForm()}
                </div>
            </div>
            <div className="login-right-panel desktop">
                {getForm()}
            </div>
    
        
    </div>
    </>
}