import { useHistory } from "react-router-dom";

import './HomeCard.css'

export default function HomeCard(props){

    const history = useHistory();

    return (
        <div className={`homecard-container ${ props.enabled ? 'enabled' : 'disabled'}`}>
            <div className="main">
                <div className="title">{props.title}</div>
                <div className="subtitle">
                    <div className="icon" style={{ backgroundImage: `url(${props.icon})` }}></div>
                    <div className="text">{props.subtitle}</div>
                </div>
            </div>
            <div className="footer">
                { props.enabled ?
                <div className="link" onClick={() => props.openInNewWindow ? window.open(props.targetPath, props.title) : window.location.hash = props.targetPath}>ENTER →</div> : 
                <div className="link">COMING SOON</div>
                }
            </div>
        </div>
    )
}