import React, { useRef, useState, useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Calendar } from 'primereact/calendar';
import { ConfirmPopup } from 'primereact/confirmpopup';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { SalesPipelineService } from "../../services/SalesPipelineService";
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';

// style
import "./CardItemDetail.style.css";

export const CardItemDetail = ({ id, trackCode, name, value, stage, date, isVisible, loadDialogData, dialogData, closeModal, reloadDialogData, disposition, showDisposition, refreshPipeline, job, config, consultants }) => {

  const userPermissions = JSON.parse(localStorage.getItem('loggedInUser'));
  const userAccountID = JSON.parse(localStorage.getItem('ajs_user_id'));

  const infoSectionRef = useRef(null);
  const notesSectionRef = useRef(null);
  const chatSectionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [notes, setNotes] = useState(dialogData.notes);
  const [onEditNotes, setOnEditNotes] = useState(false);
  const [favourite, setFavourite] = useState(dialogData.favourite);
  const [loadingCalender, setLoadingCalender] = useState(false);
  const [defaultDate, setDefaultDate] = useState(null);
  const [date1, setDate1] = useState(null);
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState(dialogData.messages);
  const [newChatMessage, setNewChatMessage] = useState("");
  const [onEditMessages, setOnEditMessages] = useState(false);

  const [dispositionValue, setDispositionValue] = useState("");
  const [consultationStatusValue, setConsultationStatusValue] = useState("");
  const [editingDisposition, setEditingDisposition] = useState(false);
  const [savingDisposition, setSavingDisposition] = useState(false);

  const consultationStatuses = [
    { label: 'Pre-Sales / Appt Set', value: 'Pre-Sales / Appt Set' },
    { label: 'Need to Reschedule', value: 'Need to Reschedule' },
    { label: 'Follow Up (Long Term)', value: 'Follow Up (Long Term)' },
    { label: 'Demo Completed', value: 'Demo Completed' },
    { label: 'No Demo (see Disposition)', value: 'No Demo (see Disposition)' },
    { label: 'Appt Cancelled', value: 'Appt Cancelled' },
    { label: 'No Show', value: 'No Show' },
    { label: 'No Longer Interested', value: 'No Longer Interested'}
];

const formatDateTime = (str) => {

  return str && str !== "" ? moment(str).utcOffset('-0700').format('MM/DD/YY') + ", " + moment(str).utcOffset('-0700').format('hh:mma') : "-"
}

const formatDate = (str) => {
  return str && str !== "" ? moment(str).utcOffset('-0700').format('MM/DD/YY') : "-"
}

const formatStringDate = (str) => {
  if(str && str !== ""){
    const dateParts = str.split("-")
    if(dateParts.length === 3){
        return `${dateParts[1]}/${dateParts[2]}/${dateParts[0].substring(2)}`
    }
  } 

  return str ?? "-";
}

const dispositions = [
  { label: 'Closed Lost - Bad Credit', value: 'Closed Lost - Bad Credit' },
  { label: 'Closed Lost - Bills Too Low', value: 'Closed Lost - Bills Too Low' },
  { label: 'Closed Lost - Ineligible Property', value: 'Closed Lost - Ineligible Property' },
  { label: 'Closed Lost - Lost to Competition', value: 'Closed Lost - Lost to Competition' },
  { label: 'Closed Lost - No Longer Interested', value: 'Closed Lost - No Longer Interested' },
  { label: 'Closed Lost - Too Much Shade', value: 'Closed Lost - Too Much Shade' },
  { label: 'Contract Sent', value: 'Contract Sent' },
  { label: 'Follow up Long Term', value: 'Follow up Long Term' },
  { label: 'Negotiating', value: 'Negotiating' },
  { label: 'SOLD - Approved', value: 'SOLD - Approved' },
  { label: 'SOLD - Credit Repair', value: 'SOLD - Credit Repair' },
  { label: 'Demo Completed', value: 'Demo Completed' },
  { label: 'No Longer Interested', value: 'No Longer Interested'}
];

  useEffect(() => {
    if (loadDialogData === true) {
      let favouritedata = dialogData.favourite
      setFavourite(favouritedata);
      let notesData = dialogData.notes
      setNotes(notesData);
      let reminderDate = dialogData.reminder_date
      setDefaultDate(reminderDate);
      setDate1(reminderDate);
      let messagesData = dialogData.messages
      setMessages(messagesData);
      setDispositionValue(disposition);
      setConsultationStatusValue(job.consultation_status)
    }
  })

  const saveDialogData = async (event, value) => {
    let selectedValue = event;
    let favoriteSelected;
    let remainderDateSelected;
    if (selectedValue === 'favorites') {
      favoriteSelected = favourite === true ? false : true;
      remainderDateSelected = date1 ? date1 : dialogData.reminder_date;
      await setFavourite(favoriteSelected);
      await setLoadingFavorites(true);
    } else if (selectedValue === 'notes') {
      await setLoading(true);
      favoriteSelected = favourite ? favourite : false;
      remainderDateSelected = date1 ? date1 : dialogData.reminder_date;
    } else if (selectedValue === 'reminder_date') {
      await setVisible(false);
      await setLoadingCalender(true);
      favoriteSelected = favourite ? favourite : false;
      remainderDateSelected = value;
    } else {
      await setLoading(true);
    }

    const service = new SalesPipelineService();
    if (selectedValue === 'messages') {
      let data = {
        opportunity_id: id,
        message: newChatMessage,
      }
      // console.log(date1, data);
      const _message_items = await service.saveMessagesData(id, data);
      setNewChatMessage("");
      console.log('updatedResponse', _message_items);
    } else {
      let data = {
        opportunity_id: id,
        sales_account_id: userAccountID,
        notes: notes,
        favourite: favoriteSelected,
        reminder_date: remainderDateSelected
      }
      // console.log(date1, data);
      const _opp_items = await service.saveDialogData(id, data);
      console.log('updatedResponse', _opp_items);
    }

    if (selectedValue === 'favorites') {
      setLoadingFavorites(false);
    } else if (selectedValue === 'notes') {
      setLoading(false);
      setOnEditNotes(!onEditNotes);
    } else if (selectedValue === 'reminder_date') {
      await setLoadingCalender(false);
    } else if (selectedValue === 'messages') {
      setLoading(false);
      setOnEditMessages(!onEditMessages);
    }
    // To the Updated Dialogue response Calling the get API
    reloadDialogData();
  }

  const saveDisposition = async () => {
    setSavingDisposition(true);
    setEditingDisposition(false);
    try 
    {
      const service = new SalesPipelineService();
      await service.updateConsultationStatusAndDisposition(id, consultationStatusValue, dispositionValue);
      setSavingDisposition(false);
      refreshPipeline();
      closeModal();
    } 
    catch (error) 
    {
      window.alert(error);
      console.log(error);
      setSavingDisposition(false);
      setEditingDisposition(true);
    }
  }


  const myIcons = (
    <>
      {!loadDialogData &&
        <>
          <button className="p-dialog-header-icon p-link" onClick={() => saveDialogData('favorites')}>
            {favourite &&
              <>
                <>{loadingFavorites && <ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} />}</>
                <>{!loadingFavorites && <i className="pi pi-star"></i>}</>
              </>
            }
            {!favourite &&
              <>
                <>{loadingFavorites && <ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} />}</>
                <>{!loadingFavorites && <i className="pi pi-star-o"></i>}</>
              </>
            }
          </button>
          <button className="p-dialog-header-icon p-link" id="button" onClick={() => setVisible(true)}>
            {loadingCalender && <ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} />}
            {!loadingCalender &&
              <>
                <span className="pi pi-calendar"></span>
                <ConfirmPopup target={document.getElementById('button')} className='calender-icon' visible={visible} onHide={() => setVisible(false)} footer
                  message={
                    <>
                      {(defaultDate || "") !== "" ? <>Current Reminder: {defaultDate}<br/><br/></> : ""}
                      <Calendar inline={visible} viewDate={new Date(defaultDate)} dateFormat='YYYY-MM-DD' value={new Date(defaultDate)} onChange={async (e) => {
                    console.log(e.value);
                    let selectedDate = await moment(e.value).format('YYYY-MM-DD');
                    await setVisible(false);
                    await setDate1(selectedDate);
                    await saveDialogData('reminder_date', selectedDate);
                  }} ></Calendar></>}
                />
              </>}
          </button>
          {(userPermissions.permissions.indexOf("VIEW_ITEM_IN_SALESFORCE") !== -1) ?
            <button className="p-dialog-header-icon p-link">
              <a href={`https://elevation.my.salesforce.com/${id}`} target="blank">
                <span className="pi pi-external-link"></span>
              </a>
            </button>
            : null}
        </>
      }
    </>
  )

  const [escalateModal, setEscalateModal] = useState(false);
  const [escalateMessage, setEscalateMessage] = useState("");
  const [escalating, setEscalating] = useState(false);

  const escalate = async () => {

    if(escalateMessage === ""){
      toast.current.show({severity:'warn', summary: 'Warning', detail:'Please provide an escalation message', life: 3000});
    }
    else {
      try 
      {
        setEscalating(true);
        const service = new SalesPipelineService();
        await service.escalate(id, escalateMessage);
        toast.current.show({severity:'success', summary: 'Success', detail:'Job escalated', life: 3000});
        setEscalateMessage("");
        setEscalating(false);
        setEscalateModal(false);
        refreshPipeline();
        closeModal();
      } 
      catch (error) 
      {
        setEscalating(false);
        toast.current.show({severity:'error', summary: 'Error', detail: error.message || error.toString(), life: 5000});
      }
      
    }
    
  }

  const [showOpportunity, setShowOpportunity] = useState(false);
  const [showECPortal, setShowECPortal] = useState(false);
  const [showProject, setShowProject] = useState(false);

  const toast = useRef(null);
  const footer = (

    <>
    <Dialog header={"Escalate Job"} className="sp-card-item-detail-escalate-modal"  visible={escalateModal} footer={
        <div className="dialog-tab-menu-item escalatebutton" onClick={() => escalate()}>
          <div className="dialog-tab-menu-item-icon escalate"><i className="pi pi-exclamation-circle"></i></div>
          <div className="dialog-tab-menu-item-text escalate">Escalate</div>
        </div>
      } onHide={() => {setEscalateModal(false); setEscalateMessage("")}}>
        <Toast ref={toast} />
        <InputTextarea rows={5} autoResize style={{ width: '100%' }} value={escalateMessage} defaultValue={''} onChange={(e) => setEscalateMessage(e.target.value)} />
    </Dialog>
    <div className="dialog-tab-menu">
      <div className="dialog-tab-menu-item" onClick={() => infoSectionRef.current.scrollIntoView()}>
        <div className="dialog-tab-menu-item-icon"><i className="pi pi-info-circle"></i></div>
        <div className="dialog-tab-menu-item-text">Job Info</div>
      </div>
      <div className="dialog-tab-menu-item" onClick={() => notesSectionRef.current.scrollIntoView()}>
        <div className="dialog-tab-menu-item-icon"><i className="pi pi-list"></i></div>
        <div className="dialog-tab-menu-item-text">Notes</div>
      </div>
      {(userPermissions.permissions.indexOf("VIEW_CHAT") !== -1) &&
        <div className="dialog-tab-menu-item" onClick={() => chatSectionRef.current.scrollIntoView()}>
          <div className="dialog-tab-menu-item-icon"><i className="pi pi-comments"></i></div>
          <div className="dialog-tab-menu-item-text">Chat</div>
        </div>}
      <div className="dialog-tab-menu-item escalate" onClick={() => setEscalateModal(true)}>
        <div className="dialog-tab-menu-item-icon escalate"><i className="pi pi-exclamation-circle"></i></div>
        <div className="dialog-tab-menu-item-text escalate">Escalate</div>
      </div>
    </div>
    </>
  );

  return (
    <>
    <Dialog header={(job.escalation || {}).escalated ? <>{name} <Tag severity="danger" value="ESCALATED"></Tag></> : name} visible={isVisible} icons={myIcons} className="sp-card-item-detail-modal" onHide={() => closeModal()}>
      <>
        {loadDialogData && <ProgressSpinner strokeWidth={'1'} />}
        {!loadDialogData && <>
          <h1 className="section-header" ref={infoSectionRef}>
            <span><i className="pi pi-info-circle" /></span>
            <span>Job Info</span>
          </h1>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Name</div>
              <div className="info-item-value">{name}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Account Number</div>
              <div className="info-item-value">{trackCode}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">System Size</div>
              <div className="info-item-value">{value} Kwz</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Stage</div>
              <div className="info-item-value">{stage}</div>
            </div>
          </div>
          { !config.hideDate &&
          <div className="info-line">            
            <div className="info-item">
              <div className="info-item-title">Date ({config.dateFieldName})</div>
              <div className="info-item-value">
                {
                  (date || "") === "" ? 
                    (config.dateFieldName || "") === "" ? "" : `'${config.dateFieldName}' not set` 
                  : 
                  date === "1900-01-01" ? <>Invalid date ({date}) on <br/>'{config.dateFieldName}'</> : config.dateFormatFunction ? <span dangerouslySetInnerHTML={{ __html: config.dateFormatFunction(date)}}/> : date}
                </div>
            </div>
          </div>
          }
          { showDisposition &&
            <div className="info-line">
            <div className="info-item">
                <div className="info-item-title">Consultation Status</div>
                { !editingDisposition && <>
                  <div className="info-item-value">{consultationStatusValue}</div>
                </>}
                { editingDisposition && <>
                  <div className="info-item-value">
                    <Dropdown value={consultationStatusValue} options={consultationStatuses} onChange={(e) => setConsultationStatusValue(e.value)}/>
                  </div>
                </>}
              </div>            
              <div className="info-item">
                <div className="info-item-title">Demo Disposition</div>
                { !editingDisposition && !savingDisposition && <>
                  <div className="info-item-value">{disposition}&nbsp;<i className="pi pi-pencil" style={{cursor: 'pointer'}} onClick={() => setEditingDisposition(true)}></i></div>
                </>}
                { editingDisposition && <>
                  <div className="info-item-value">
                    <Dropdown value={dispositionValue} options={dispositions} onChange={(e) => setDispositionValue(e.value)}/>
                    &nbsp;&nbsp;<i className="pi pi-check" style={{cursor: 'pointer'}} onClick={() => saveDisposition()}></i>
                    &nbsp;&nbsp;<i className="pi pi-times" style={{cursor: 'pointer'}} onClick={() => {
                      setDispositionValue(disposition);
                      setEditingDisposition(false);
                    }}></i>
                  </div>
                </>}
                { savingDisposition && <>
                  <div className="info-item-value">
                    {dispositionValue}
                    &nbsp;&nbsp;<ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} />
                  </div>
                </>}
              </div>
            </div>
          }
          
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Market</div>
              <div className="info-item-value">{job.market || "Unkown"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">EC</div>
              <div className="info-item-value">{ job.ec_name === "Unknown" ? (consultants.find(c => c.id === job.ec_email) || { name: "Unknown"}).name : job.ec_name}<br/>{job.ec_email}</div>
            </div>
          </div>
          <h1 className="section-header" onClick={() => setShowOpportunity(!showOpportunity)} style={{cursor: 'pointer'}}>
            <span><i className={`pi ${showOpportunity ? "pi-chevron-down" : "pi-chevron-right"}`} /></span>
            <span>Opportunity</span>
          </h1>
          { showOpportunity && <>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Customer Email</div>
              <div className="info-item-value">{dialogData.opportunity.Email__c}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Mobile Phone</div>
              <div className="info-item-value">{dialogData.opportunity.Mobile_Phone__c}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Preliminary Agreement Sent</div>
              <div className="info-item-value">{formatStringDate(dialogData.opportunity.Preliminary_Agreement_Sent__c) ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Preliminary Agreement Signed</div>
              <div className="info-item-value">{formatStringDate(dialogData.opportunity.Preliminary_Agreement_Signed__c) ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Welcome Call Date</div>
              <div className="info-item-value">{formatStringDate(dialogData.opportunity.Welcome_Call_Date__c) ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Welcome Call Completed By</div>
              <div className="info-item-value">{dialogData.opportunity.Welcome_Call_Completed_By__r?.Name ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">System Size (kW)</div>
              <div className="info-item-value">{dialogData.opportunity.System_Size_kW__c ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Module Type</div>
              <div className="info-item-value">{dialogData.opportunity.Module_Type_new__r?.Name ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Battery Unit Count</div>
              <div className="info-item-value">{dialogData.opportunity.Battery_Unit_Count__c ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Other Services</div>
              <div className="info-item-value">{dialogData.opportunity.Other_Services__c ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Utility Company</div>
              <div className="info-item-value">{dialogData.opportunity.Utility_Company_Name__c ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Advisor Team Notes</div>
              <div className="info-item-value">{dialogData.opportunity.Advisor_Team_Notes__c ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Advisor</div>
              <div className="info-item-value">{dialogData.opportunity.Lead_Gen__r?.Name ?? "-"}</div>
            </div>
          </div>
          </>}
          { (dialogData.ecportal.Id || "") !== "" && <>
          <h1 className="section-header" onClick={() => setShowECPortal(!showECPortal)} style={{cursor: 'pointer'}}>
            <span><i className={`pi ${showECPortal ? "pi-chevron-down" : "pi-chevron-right"}`} /></span>
            <span>EC Portal</span>
          </h1>
          { showECPortal && <>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Consultation - Appt Status</div>
              <div className="info-item-value">{dialogData.ecportal.Consultation_Appt_Status__c ?? "-"}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Demo Disposition</div>
              <div className="info-item-value">{dialogData.ecportal.Demo_Disposition__c ?? "-"}</div>
            </div>
          </div>
          <div className="info-line">
            <div className="info-item">
              <div className="info-item-title">Demo Timestamp</div>
              <div className="info-item-value">{formatDateTime(dialogData.ecportal.Demo_Timestamp__c)}</div>
            </div>
            <div className="info-item">
              <div className="info-item-title">Consultation Date / Time</div>
              <div className="info-item-value">{formatDateTime(dialogData.ecportal.Consultation_Date_Time__c)}</div>
            </div>
          </div>
          </>}</>}
          { (dialogData.project.Id || "") !== "" && <>
            <h1 className="section-header" onClick={() => setShowProject(!showProject)} style={{cursor: 'pointer'}}>
              <span><i className={`pi ${showProject ? "pi-chevron-down" : "pi-chevron-right"}`} /></span>
              <span>Project</span>
            </h1>
            { showProject && <>
            <div className="info-line">
              <div className="info-item">
                <div className="info-item-title">Stage</div>
                <div className="info-item-value">{dialogData.project.Stage__c}</div>
              </div>
              <div className="info-item">
                <div className="info-item-title">Sub Stage</div>
                <div className="info-item-value">{dialogData.project.Sub_Stage__c}</div>
              </div>
            </div>
            <div className="info-line">
              <div className="info-item">
                <div className="info-item-title">Stage Duration</div>
                <div className="info-item-value">{dialogData.project.Stage_Duration__c}</div>
              </div>
              <div className="info-item">
                <div className="info-item-title">Sub Stage Duration</div>
                <div className="info-item-value">{dialogData.project.Sub_Stage_Duration__c}</div>
              </div>
            </div>
            <div className="info-line">
              <div className="info-item">
                <div className="info-item-title">Correction Status</div>
                <div className="info-item-value">{dialogData.project.Correction_Status__ ?? "-"}</div>
              </div>
              <div className="info-item">
                <div className="info-item-title">Correction Sub Status</div>
                <div className="info-item-value">{dialogData.project.Correction_Sub_Status__c ?? "-"}</div>
              </div>
            </div>
            <div className="info-line">
              <div className="info-item">
                <div className="info-item-title">Correction Status Duration</div>
                <div className="info-item-value">{dialogData.project.Correction_Status_Duration__c ?? "-"}</div>
              </div>
              <div className="info-item">
                <div className="info-item-title">Correction Sub Status Duration</div>
                <div className="info-item-value">{dialogData.project.Correction_Substatus_Duration__c ?? "-"}</div>
              </div>
            </div>
            <div className="info-line">
              <div className="info-item"><div className="info-item-title">Final Docs</div><div className="info-item-value">{formatStringDate(dialogData.project.Final_Docs__c) ?? "-"}</div></div>
              <div className="info-item"><div className="info-item-title">Loan Expiration Date</div><div className="info-item-value">{dialogData.project.Loan_Expiration_Date_Formula__c ?? "-"}</div></div>
              </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Site Survey Scheduled Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Site_Survey_Scheduled__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Site Survey Completed Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Site_Survey_Completed__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">PreDesign Start Date</div><div className="info-item-value">{formatStringDate(dialogData.project.PreDesign_Start__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">PreDesign Completed Date</div><div className="info-item-value">{formatStringDate(dialogData.project.PreDesign_Completed__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
              <div className="info-item"><div className="info-item-title">Design Start Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Design_Start__c) ?? "-"}</div></div>
              <div className="info-item"><div className="info-item-title">Design Complete Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Design_Complete_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
              <div className="info-item"><div className="info-item-title">Design QA/QC Start Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Design_QA_QC_Start__c) ?? "-"}</div></div>
              <div className="info-item"><div className="info-item-title">Design QA/QC Completed Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Design_QA_QC_Completed__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Permit Submitted Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Permit_Submitted__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Permit Expected Approval Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Permit_Expected_Approval__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Permit Approved Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Permit_Approved__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Utility Application Submitted Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Utility_Application_Submitted_Date__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Utility Expected Approval Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Utility_Expected_Approval__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Utility Application Approved Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Utility_Application_Approved_Date_New__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">HOA Application Submitted Date</div><div className="info-item-value">{formatStringDate(dialogData.project.HOA_Application_Submitted_Date_New__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">HOA Expected Approval Date</div><div className="info-item-value">{formatStringDate(dialogData.project.HOA_Expected_Approval_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">HOA Application Approved Date</div><div className="info-item-value">{formatDateTime(dialogData.project.HOA_Application_Approved_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Electrical Work Required</div><div className="info-item-value">{dialogData.project.Electrical_Work_Required__c ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Electrical Work Required Type</div><div className="info-item-value">{dialogData.project.Electrical_Work_Required_Type__c ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Installation Scheduled Start Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Installation_Scheduled_Start_Date__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Installation Scheduled End Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Installation_Scheduled_End_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Installation Completed</div><div className="info-item-value">{formatStringDate(dialogData.project.Installation_Completed__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Final Inspection Passed Date</div><div className="info-item-value">{formatDateTime(dialogData.project.Final_Inspection_Actual_End_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Interconnection Submitted Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Interconnection_Submitted__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Interconnection Expected Approval Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Interconnection_Expected_Approval__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">PTO Granted Date</div><div className="info-item-value">{formatDateTime(dialogData.project.PTO_Granted_Date__c) ?? "-"}</div></div>
            <div className="info-item"><div className="info-item-title">Monitoring Complete Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Monitoring_Complete_Date__c) ?? "-"}</div></div>
            </div>
            <div className="info-line">
            <div className="info-item"><div className="info-item-title">Exit Call Completed Date</div><div className="info-item-value">{formatStringDate(dialogData.project.Exit_Call_Completed_Date__c) ?? "-"}</div></div>
            </div>
            </>}
          </>}
          <h1 className="section-header" ref={notesSectionRef}>
            <span><i className="pi pi-list" /></span>
            <span>Notes</span>
          </h1>
          <div>Coming soon...</div>
          {false && !onEditNotes ?
            (dialogData && dialogData.notes) ?
              <div>{dialogData.notes.split("\n").map(function(item, idx) {
                return (
                    <span key={idx}>
                        {item}
                        <br/>
                    </span>
                )
            })}</div>
              : <div>You have no notes about this opportunity. Click on Edit to add the first one.</div>
            : null}
          {false && onEditNotes &&
            <InputTextarea rows={5} autoResize style={{ width: '100%' }} value={notes} defaultValue={dialogData.notes} onChange={(e) => setNotes(e.target.value)} />
          }
          {false && onEditNotes &&
            <div className="content-button" onClick={() => saveDialogData('notes')}>
              {loading && onEditNotes && <div><ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} /></div>}
              {!loading && <>
                <div className="content-button-icon"><i className="pi pi-check"></i></div>
                <div className="content-button-text">Save</div>
              </>}
            </div>}
          {false && !onEditNotes &&
            <div className="content-button" onClick={() => setOnEditNotes(!onEditNotes)}>
              <div className="content-button-icon"><i className="pi pi-pencil"></i></div>
              <div className="content-button-text">Edit</div>
            </div>}

          {(userPermissions.permissions.indexOf("VIEW_CHAT") !== -1) && <>
            <h1 className="section-header" ref={chatSectionRef}>
              <span><i className="pi pi-comments" /></span>
              <span>Chat</span>
            </h1>
            <div>Coming soon...</div>
            {false && <>{(dialogData && dialogData.messages && dialogData.messages.length > 0) ?
                dialogData.messages.length > 0 &&
                dialogData.messages.map((m) => {
                  return (
                    <div className="chat-item" key={m.id}>
                      <div className="chat-item-header">
                        <div className="chat-item-ts">{moment(m.timestamp).format('YYYY-MM-DD HH:mm')}</div>
                        <div className="chat-itemitem-author">{m.author}</div>
                      </div>
                      <div className="chat-item-content">
                        {m.message.split("\n").map(function(item, idx) {
                              return (
                                  <span key={idx}>
                                      {item}
                                      <br/>
                                  </span>
                              )
                          })}
                      </div>
                    </div>
                  )
                })
                : <div>No messages yet. Click 'Add' to post the first one.</div>
            }</>}
            {false && onEditMessages &&
              <InputTextarea rows={5} autoResize style={{ width: '100%' }} value={newChatMessage} defaultValue={''} onChange={(e) => setNewChatMessage(e.target.value)} />
            }
            {false && onEditMessages &&
              <div className="content-button" onClick={() => saveDialogData('messages')}>
                {loading && onEditMessages && <div><ProgressSpinner className="p-progress-spinner-submit" strokeWidth={1} /></div>}
                {!loading && <>
                  <div className="content-button-icon"><i className="pi pi-check"></i></div>
                  <div className="content-button-text">Save</div>
                </>}
              </div>}
            {false && !onEditMessages &&
              <div className="content-button" onClick={() => setOnEditMessages(!onEditMessages)}>
                <div className="content-button-icon"><i className="pi pi-plus-circle"></i></div>
                <div className="content-button-text">Add</div>
              </div>}
          </>}
        </>}
      </>
    </Dialog>
    </>
  );
};
