import { BaseService } from './_BaseService';

export class ReferralService extends BaseService {

    batchSave = async (referrer, referrals, fromec) => {
        return await this.post(`sales/batch-save-referrals`, { referrer, referrals, fromec });
    }

    sendLink = async (referrer, fromSales) => {
        return await this.post(`sales/send-link`, { referrer, fromSales });
    }

    list = async () => {
        return await this.get(`sales/referrals`);
    }


    static showMeetingPaymentLink = (referral, payments) => {
        const meetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING");
        return meetingPayment && meetingPayment.status !== "PENDING_APPROVAL";
    }

    static getAppointmentStatus = (referral, payments) => {

        let _status = "Contacting";
        if(referral.status === "MEETING_SCHEDULED"){
            _status = "Set"
        }
        else if(referral.status === "MEETING_DONE" || referral.status === "ACTIVATED"){
            _status = "Set";
            const meetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING");
            if(meetingPayment){
                console.log(meetingPayment.status)
                if(meetingPayment.status === "PENDING_APPROVAL"){
                    _status = "Set";    
                }
                else if(meetingPayment.status === "PENDING_CONFIG"){
                    _status = "Pending Payment Options Confirmation";    
                }
                else if(meetingPayment.status === "PENDING_PAYMENT"){
                    _status = `Processing Payment`;
                }
                else if(meetingPayment.status === "PAID"){
                    _status = `${(meetingPayment.data.option || "") === "donation" ? "Donation" : "Cash"} Paid`;
                }
            }
        }
        else if(referral.status === "Not Interested"){
            _status = "Not Interested";
        }
        else if(referral.status === "Submitted"){
            _status = "Submitted";
        }

        return _status;
    }

    static showActivationPaymentLink = (referral, payments) => {
        const finalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS");
        return finalPayment && finalPayment.status !== "PENDING_APPROVAL";
    }

    static getActivationStatus = (referral, payments) => {

        let _status = "Pending";
        if(referral.status === "MEETING_SCHEDULED"){
            _status = "Pending"
        }
        else if(referral.status === "MEETING_DONE"){
            _status = "Pending";
        }
        else if(referral.status === "ACTIVATED"){
            _status = "Sale";
            // const finalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS");
            // if(finalPayment){
            //     if(finalPayment.status === "PENDING_APPROVAL"){
            //         _status = "-";    
            //     }
            //     else if(finalPayment.status === "PENDING_CONFIG"){
            //         _status = "Pending Payment Options Confirmation";  
            //     }
            //     else if(finalPayment.status === "PENDING_PAYMENT"){
            //         _status = `Processing ${(finalPayment.data.config.option || "") === "donation" ? "Donation" : "Cash"} Payment`;  
            //     }
            //     else if(finalPayment.status === "PAID"){
            //         _status = `${(finalPayment.data.option || "") === "donation" ? "Donation" : "Cash"} Paid`;
            //     }
            // }
        }

        return _status;
    }
}