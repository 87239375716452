import React, { Component } from 'react';

import { Button } from 'primereact/button';

// Components
import { Header } from './components/Header';
import { Footer } from './components/Footer';

// Screens
import { Welcome } from './screens/Welcome';
import { Login } from './screens/Login';
import { Home } from './screens/Home';
import { Profile } from './screens/Profile';
import { ReferralsForm } from './screens/ReferralsForm';
import { ReferralsFormSales } from './screens/ReferralsFormSales';
import { ReferralsList } from './screens/ReferralsList';

import { Route, withRouter } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import './assets/css/main.css';
import './assets/css/login.css';
import './assets/css/internal.css';
import './assets/css/right-tabs.css';
import './assets/css/form.css';
import './assets/css/form-modal.css';
import './assets/css/list.css';

import { AuthorizationService } from './services/AuthorizationService';

import { createBrowserHistory } from "history";
import SalesDashboard from './screens/SalesDashboard';
import SalesPipeline from './screens/SalesPipeline';
const customHistory = createBrowserHistory();

let prevPath = null;
// listen and notify Segment of client-side page updates
customHistory.listen((location) => {
    // console.log(prevPath, location);
    if (location.hash !== prevPath) {
    prevPath = location.hash;
    window.analytics.page(location.hash);
  }
});

class App extends Component {

    constructor() {
        super();
        this.state = {};
    }

    async componentDidMount() 
    {
        this.authorizationService = new AuthorizationService();
        this.setState({
            isLoggedIn: this.authorizationService.isLoggedIn(),
            loggedInUser: this.authorizationService.loggedInUser
        })
    }

    onAuthenticatedUserUpdate(user){
        this.authorizationService.setLoggedInUser(user);
        this.setState({
            isLoggedIn: this.authorizationService.isLoggedIn(),
            loggedInUser: this.authorizationService.loggedInUser
        });
    }

    onAuthenticated(user) {
        this.onAuthenticatedUserUpdate(user);
        this.props.history.push("/");
    }

    onSignedUp(user) {
        this.onAuthenticatedUserUpdate(user);
        this.props.history.push("/profile");
    }

    

    logout() {
        this.authorizationService.logout();
        this.setState({
            isLoggedIn: false,
            loggedInUser: null
        })
        this.props.history.push("/");
    }

    updateCounters(referrals) {
        console.log("updateCounters", referrals.length);
        this.setState({
            counters: {
                referrals: referrals.length,
                meetings: 0,
                sales: 0
            }
        })
    }

    render() {
        const { isLoggedIn, loggedInUser } = this.state;

        return (
        <div className="App">
            { isLoggedIn && <Header user={loggedInUser} onLogout={this.logout.bind(this)}/> }
            <div className="AppContent">
                { isLoggedIn && <>
                    <div className={`screen internal ${ (this.props.location.pathname || "").substring(1) === "" ? "home" : (this.props.location.pathname || "").substring(1) }`}>
                        <Route path="/" exact component={() => <Home user={loggedInUser}/>} />
                        <Route path="/profile" exact component={() => <Profile user={loggedInUser} onUserUpdate={this.onAuthenticatedUserUpdate.bind(this)}/>} />
                        { 
                            this.authorizationService.can(AuthorizationService.Permissions.ACCESS_REFERRAL_PROGRAM) && 
                            <>
                                <Route path="/referral-form" exact component={() => <ReferralsForm user={loggedInUser}/>}/>
                                <Route path="/referral-list" exact component={() => <ReferralsList user={loggedInUser}/>}/>
                                <Route path="/referral-form-sales" exact component={() => <ReferralsForm user={loggedInUser}/>}/>
                            </>
                        }
                        { 
                            this.authorizationService.can(AuthorizationService.Permissions.ACCESS_SALES_DASHBOARD) && 
                            <>
                                <Route path="/sales-dashboard" exact component={() => <SalesDashboard user={loggedInUser}/>}/>
                            </>
                        }
                        { 
                            this.authorizationService.can(AuthorizationService.Permissions.ACCESS_SALES_PIPELINE) && 
                            <>
                                <Route path="/sales-pipeline" exact component={() => <SalesPipeline user={loggedInUser}/>}/>
                            </>
                        }

                    </div>
                </>}
                { !isLoggedIn && <>
                    <Route path="/signup" exact component={() => <Welcome onAuthenticated={this.onAuthenticated.bind(this)} />} />
                    <Route path="/" exact component={() => <Login onAuthenticated={this.onAuthenticated.bind(this)}/>} />
                </>}
            </div>
        </div>
        )
    }
}

export default withRouter(App);
