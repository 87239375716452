import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Password } from 'primereact/password';

import { AuthorizationService } from '../services/AuthorizationService';

export function Login(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [showWrongCredentialsMessage, setShowWrongCredentialsMessage] = useState(false);

    const [showPasswordRecover, setShowPasswordRecover] = useState(false);
    
    const [sendingCode, setSendingCode] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [checkingCode, setCheckingCode] = useState(false);
    const [codeChecked, setCodeChecked] = useState(false);
    
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [savingPassword, setSavingPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordReset, setPasswordReset] = useState(false);

    const login = async () => 
    {
        try 
        {
            setAuthenticating(true);
            setShowWrongCredentialsMessage(false);
            const authorizationService = new AuthorizationService();
            const user = await authorizationService.login(email, password);
            window.analytics.identify(user.email, {
                name: user.name,
                email: user.email,
            });
            props.onAuthenticated(user);
            setAuthenticating(false);
        } 
        catch (error) 
        {
            setAuthenticating(false);
            console.log(error);
            if(error.code && error.code === 401){
                setShowWrongCredentialsMessage(true);
            }
            else {
                alert(error.code ? error.code + " - " + error.message : error);
            }            
        }
    }

    const backToLogin = () => {
        setCode("");
        setCodeError(false)
        setCodeSent(false);
        setCodeChecked(false);
        setPasswordConfirmation("");
        setPasswordError(null);
        setShowPasswordRecover(false);
        setPassword("");
        setShowWrongCredentialsMessage(false);
    }

    const sendCode = async () => {
        try {
            setCode("");
            setCodeError(false);
            setSendingCode(true);
            const service = new AuthorizationService();
            await service.sendToken(email);
            setSendingCode(false);
            setCodeSent(true);    
        } 
        catch (error) 
        {
            setSendingCode(false);
            setCodeSent(false);    
            console.log(error);
            window.alert(error.message || error);
        }
        
    }

    const checkCode = async () => {
        
        try {
            setCodeError(false);
            setCheckingCode(true);
            const service = new AuthorizationService();
            await service.checkToken(code);
            setCheckingCode(false);
            setCodeChecked(true); 
        } 
        catch (error) 
        {
            console.log(error);
            setCheckingCode(false);
            setCodeChecked(false);    
            if(error.code && error.code === 400){
                setCodeError(true);
            }
            else {
                window.alert(error.message || error);
            }
        }
    }

    const resetPassword = async () => {
        setPasswordError(null)
        if(password !== passwordConfirmation){
            setPasswordError("Password confirmation does not match")
        }
        else {
            try {
                setSavingPassword(true);
                const service = new AuthorizationService();
                await service.resetPassword(password);
                setSavingPassword(false);
                setPasswordReset(true);
            } 
            catch (error) 
            {
                console.log(error);
                setSavingPassword(false);
                if(error.code && error.code === 400){
                    setPasswordError("Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.");
                }
                else {
                    window.alert(error.message || error);
                }
            }
        }
    }

    const getForm = () => {
        return <div className="form-box">
        { !showPasswordRecover && <>
            <div className='fields'>
                <div className="formfield">
                    <InputText 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="User Name" 
                    />
                </div>
                <div className="formfield">
                    <InputText 
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password" 
                    />
                </div>
            </div>
            <div className="buttons">
                <div className='button login' onClick={login}>
                    <div>Log In</div>
                    <div>→</div>
                </div>
            </div>
            { showWrongCredentialsMessage && <div className="fieldErrorMessage">Wrong credentials.</div>}
            <div className="existing-account-message">Haven’t registered yet? Click <Link to={`signup`}>here</Link></div>
            <div className="existing-account-message">Can't remember your password? Click <a href="#" onClick={() => setShowPasswordRecover(true)}>here</a></div>
            { (authenticating) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
        </>}
        { showPasswordRecover && !codeSent &&
            <>
                <div className="description">To reset your password, we will send you an email verification code.</div>
                <div className='fields'>
                    <div className="formfield">
                        <InputText 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email" 
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div disabled={sendingCode || email === ""}  className='button sendcode' onClick={sendCode}>
                        <div>Send me a verification code</div>
                        <div>→</div>
                    </div>
                </div>
                <div className="existing-account-message">Back to login? Click <a href="#" onClick={backToLogin}>here</a></div>
                { sendingCode && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
            </>
        }
        { showPasswordRecover && codeSent && !codeChecked && 
            <>
                <div className="description">We've sent a verification code to <b>{ email }</b>. Please check Your inbox, fill the received code on the field below, and click "Verify".</div>
                <div className='fields'>
                    <div className="formfield">
                        <InputText 
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Code" 
                        />
                    </div>
                </div>
                { codeError && <div className="fieldErrorMessage">Invalid verification code</div> }
                <div className="buttons">    
                    <Button disabled={sendingCode || checkingCode} label="Verify →" className="button" onClick={checkCode} />
                    <Button disabled={sendingCode || checkingCode} label="Send again →" className="button" onClick={sendCode} />
                </div>
                <div className="existing-account-message">Back to login? Click <a href="#" onClick={backToLogin}>here</a></div>
                { (sendingCode || checkingCode) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
            </>
        }
        { showPasswordRecover && codeChecked && !passwordReset && 
            <>
                <div className="description">Verification successful. Please create your new password.</div>
                <div className='fields'>
                    <div className="formfield">
                        <div className='password-form-field' style={{width: '80%'}}>
                        <Password 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder="Password"
                            style={{width: '100%'}}
                            inputStyle={{width: '100%'}}
                            promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                            weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                            mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                            strongLabel="We are good to go."
                            toggleMask={true}
                        />
                        </div>
                    </div>
                    <div className="formfield">
                        <div className='password-form-field' style={{width: '80%'}}>
                        <Password 
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            placeholder="Re-Type" 
                            feedback={false}
                            toggleMask={true}
                            style={{width: '100%'}}
                            inputStyle={{width: '100%'}}
                        />
                        </div>
                    </div>
                </div>
                { passwordError && <div className="fieldErrorMessage">{passwordError}</div> }
                <div className="buttons">    
                    <Button disabled={savingPassword} label="Save →" className="button" onClick={resetPassword} />
                </div>
                <div className="existing-account-message">Back to login? Click <a href="#" onClick={backToLogin}>here</a></div>
                { (savingPassword) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
            </>
        }
        { showPasswordRecover && passwordReset && 
            <>
                <div className="description">Password reset successfully.</div>
                <div className="buttons">    
                    <Button label="Back to Login" className="button" onClick={backToLogin}/>
                </div>
            </>
        }
    </div>
    }

    return <div className="screen login">
            <div className="login-left-panel">
                <div className='logo'>
                    <img id="logo" alt="Elevation Solar" src="assets/layout/images/logo.png"/>
                </div>
                <div className='title'>Welcome to<br/>Elevation<br/>Sales Dashboard</div>
                <div className='sub-title'>For Elevation Sales Team</div>
                <div className="login-right-panel mobile">
                    {getForm()}
                </div>
            </div>
            <div className="login-right-panel desktop">
                {getForm()}
            </div>
    </div>
}